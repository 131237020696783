import React, { useContext, useState } from 'react';
import {
    ChakraProvider,
    Box,
    Center,
    Progress,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Flex,
    Textarea,
    Button,
    useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { MyContext } from '../../Context/Appcontext';
import { useNavigate } from 'react-router-dom';


const MultistepForm = () => {
    let { myData, formData, setFormData } = useContext(MyContext);
    const [price, setPrice] = useState(myData.price);
    let navigate = useNavigate()


    let toast = useToast();
    let carts = [myData];
    // const x = JSON.parse(localStorage.getItem('formData'));
    const submitForm = async () => {
        console.log(formData)
        try {

            await axios.post('https://db-be-hheq.onrender.com/certifications', formData);
            toast({
                title: 'Quality certification requested.',
                description: "We've received your quality certification request.",
                status: 'success',
                duration: 1000,
                isClosable: true,
            });
            navigate('/');
        } catch (error) {
            console.error('Error submitting form:', error);
            toast({
                title: 'Error',
                description: 'An error occurred while submitting the form.',
                status: 'error',
                duration: 1000,
                isClosable: true,
            });
        }
    };


    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(33.33);



    const handleInputChange = (section, field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [section]: {
                ...prevData[section],
                [field]: value,
            },
        }));
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
            setProgress(progress - 33.33);
        }
    };



    const handleNext = () => {
        let valid = true;

        if (step === 1) {
            // Validate contact information fields

            // Email validation
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!formData.contactInfo.email || !emailPattern.test(formData.contactInfo.email)) {
                valid = false;
                toast({
                    title: 'Error',
                    description: 'Please enter a valid email address.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }

            if (!formData.contactInfo.name || !formData.contactInfo.phone) {
                valid = false;
                toast({
                    title: 'Error',
                    description: 'Please fill in all contact information fields.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } else if (step === 2) {
            // Validate certification information fields

            // PAN Card validation
            const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
            if (!formData.certificationInfo.panCard || !panCardPattern.test(formData.certificationInfo.panCard)) {
                valid = false;
                toast({
                    title: 'Error',
                    description: 'Please enter a valid PAN Card number.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }

            // GSTIN validation
            const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
            if (!formData.certificationInfo.gstin || !gstinPattern.test(formData.certificationInfo.gstin)) {
                valid = false;
                toast({
                    title: 'Error',
                    description: 'Please enter a valid GSTIN.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }

        if (valid) {
            if (step < 3) {
                setPrice(myData.price);
                setStep(step + 1);
                setProgress(progress + 33.33);
                setFormData({
                    ...formData,
                    certificationInfo: {
                        ...formData.certificationInfo,
                        certification: myData.title,
                    },
                    paymentInfo: {
                        ...formData.paymentInfo,
                        amount: price,
                    },
                });
            } else {
                localStorage.setItem('formData', JSON.stringify(formData));
                submitForm();
            }
        }
    };



    return (
        <ChakraProvider>
            <Center mt={"40px"} h={{ base: '70h', md: '70vh', lg: '70vh' }}>
                <Box
                    borderWidth="1px"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    maxWidth={800}
                    p={6}
                    w="100%"
                >
                    <Progress
                        hasStripe
                        value={progress}
                        mb="5%"
                        mx="5%"
                        colorScheme="red"
                        size="lg"
                        isAnimated
                    />

                    {step === 1 && (
                        <div>
                            <Heading textAlign="center" fontWeight="bold" mb="2%">
                                Contact Information
                            </Heading>
                            <FormControl mt="2%">
                                <FormLabel htmlFor="contact-name" fontWeight="semibold">
                                    Name
                                </FormLabel>
                                <Input
                                    id="contact-name"
                                    placeholder="Name"
                                    value={formData.contactInfo.name}
                                    onChange={(e) =>
                                        handleInputChange('contactInfo', 'name', e.target.value)
                                    }
                                />
                            </FormControl>

                            <FormControl mt="2%">
                                <FormLabel htmlFor="contact-email" fontWeight="semibold">
                                    Email
                                </FormLabel>
                                <Input
                                    id="contact-email"
                                    type="email"
                                    placeholder="Email"
                                    value={formData.contactInfo.email}
                                    onChange={(e) =>
                                        handleInputChange('contactInfo', 'email', e.target.value)
                                    }
                                />
                            </FormControl>

                            <FormControl mt="2%">
                                <FormLabel htmlFor="contact-phone" fontWeight="semibold">
                                    Phone
                                </FormLabel>
                                <Input
                                    id="contact-phone"
                                    type="tel"
                                    placeholder="Phone"
                                    value={formData.contactInfo.phone}
                                    onChange={(e) =>
                                        handleInputChange('contactInfo', 'phone', e.target.value)
                                    }
                                />
                            </FormControl>
                        </div>
                    )}

                    {step === 2 && (
                        <div>
                            <Heading textAlign="center" fontWeight="bold" mb="2%">
                                Certification Information
                            </Heading>
                            <FormControl mt="2%">
                                <FormLabel htmlFor="certification" fontWeight="semibold">
                                    Type of Certification:
                                </FormLabel>
                                <Input
                                    id="certification"
                                    // placeholder="Select a Certification"
                                    value={myData.title}
                                />
                            </FormControl>
                            <FormControl mt="2%">
                                <FormLabel htmlFor="pan-card" fontWeight="semibold">
                                    PAN Card :
                                </FormLabel>
                                <Flex alignItems="center">
                                    <Input
                                        id="pan-card"
                                        type="text"
                                        placeholder="Enter PAN Card No."
                                        value={formData.certificationInfo.panCard}
                                        onChange={(e) =>
                                            handleInputChange(
                                                'certificationInfo',
                                                'panCard',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Flex>
                            </FormControl>
                            <FormControl mt="2%">
                                <FormLabel htmlFor="gstin" fontWeight="semibold">
                                    GSTIN
                                </FormLabel>
                                <Input
                                    id="gstin"
                                    type="text"
                                    placeholder="GSTIN"
                                    value={formData.certificationInfo.gstin}
                                    onChange={(e) =>
                                        handleInputChange(
                                            'certificationInfo',
                                            'gstin',
                                            e.target.value
                                        )
                                    }
                                />
                            </FormControl>
                            {/* Add other certification info fields here */}
                        </div>
                    )}

                    {step === 3 && (
                        <div>
                            <Heading textAlign="center" fontWeight="bold" mb="2%">
                                Additional Comments
                            </Heading>
                            <FormControl mt="2%">
                                <FormLabel htmlFor="comments" fontWeight="semibold">
                                    Comments
                                </FormLabel>
                                <Textarea
                                    id="comments"
                                    placeholder="Add any additional comments here..."
                                    value={formData.additionalInfo.comments}
                                    onChange={(e) =>
                                        handleInputChange('additionalInfo', 'comments', e.target.value)
                                    }
                                />
                            </FormControl>
                        </div>
                    )}

                    <Flex mt="2%" w="100%" justifyContent="space-between">
                        {step !== 1 && (
                            <Button
                                type="button"
                                onClick={handleBack}
                                backgroundColor="#ef6042"
                                variant="solid"
                                w="7rem"
                                mr="5%"
                                color="white"
                            >
                                Back
                            </Button>
                        )}
                        <Button
                            type="button"
                            onClick={handleNext}
                            backgroundColor="#ef6042"
                            variant="solid"
                            color="white"
                            w="8rem"
                            p={2}
                        >
                            {step === 3 ? 'Apply Now' : 'Next'}
                        </Button>

                    </Flex>
                </Box>
            </Center>
        </ChakraProvider>
    );
};

export default MultistepForm;
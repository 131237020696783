import React from 'react'
import Contactbody from './ContactBody'

const Contact = () => {
    return (
        <div style={{marginTop: "-4%" }}>
            <Contactbody />
        </div>
    )
}

export default Contact

import React from 'react';
import ContactChart from './ContactChart';
import CertificationChart from './CertificationChart';


const DashboardPage = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px', // Adjust the gap as needed
    padding: '20px',
  };

  return (
    <>
      <div style={containerStyle}>
        <ContactChart />
        <CertificationChart />
      </div>

    </>
  );
};

export default DashboardPage;

import React from "react";
import {
    Step,
    StepDescription,
    StepIndicator,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Box,
    Center,
} from "@chakra-ui/react";
import { FaPencilAlt, FaCreditCard, FaCheckCircle } from "react-icons/fa";

const steps = [
    { title: "Step 1", description: "Fill out the details" },
    { title: "Step 2", description: "Proceed To Payment" },
    { title: "Step 3", description: "Applied Successfully!" },
];

const Steps = () => {
    const { activeStep } = useSteps({
        index: 1,
        count: steps.length,
    });

    return (
        <Center> {/* Center the content vertically */}
            <Stepper
                size="lg"
                colorScheme="red"
                width={{ base: "90%", md: "50%" }} // Adjust the width for responsiveness
                marginTop={{ base: "20%", md: "10%",lg:"10%" }}
                index={activeStep}
                direction={{ base: "column", md: "row" }} // Display steps vertically on small screens and horizontally on medium screens and above
                spacing={4} // Add spacing between steps
            >
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator>
                            <StepStatus
                                complete={<FaPencilAlt style={{ color: "white" }} />}
                                incomplete={<FaCreditCard style={{ color: "#ef6042" }} />}
                                active={<FaCheckCircle style={{ color: "#ef6042" }} />}
                            />
                        </StepIndicator>
                        <Box flexShrink="0">
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                        </Box>
                        {index < steps.length - 1 && <StepSeparator />}
                    </Step>
                ))}
            </Stepper>
        </Center>
    );
};

export default Steps;

import React from 'react'
import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import MultistepForm from '../components/SingleCertificateForm/Form';
import Steps from '../components/SingleCertificateForm/Steps';
import NewsLetter from '../components/FooterComponents/Newsletter';
import Footer from '../components/FooterComponents/Footer';
import ContactFloating from '../components/ContactFLoating/ContactFloating';
import { Helmet } from 'react-helmet';


const SingleCertificatePage = () => {
    return (
        <div style={{ width: "100%", overflow: "hidden" }}>
            <Helmet>
                <meta name="Digiblocks | Apply for Certification" content="Digiblocks | Apply for Certification" />
                <title>Digiblocks | Apply for Certification</title>
            </Helmet>
            <ChakraProvider>
                <Header />
                <ContactFloating />
            </ChakraProvider>

            <ChakraProvider>
                <Steps />
                <MultistepForm />
            </ChakraProvider>


            <NewsLetter />
            <Footer />
        </div>
    )
}

export default SingleCertificatePage;

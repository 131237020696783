import React from "react";
import Testimonials from "../components/DevelopmentPageComponents/Testimonials";
import Top from "../components/DevelopmentPageComponents/Top";
import Mid from "../components/DevelopmentPageComponents/Mid";
import WordCloud from "../components/DevelopmentPageComponents/WordCloud";
import NewContact from "../components/ContactComponents/Contact";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import Backtotop from "../components/Backtotop/Backtotop";
import ContactFloating from "../components/ContactFLoating/ContactFloating";
import { Helmet } from 'react-helmet'


const DevelopmentPage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Helmet>
        <meta name="Digiblocks | Web Development Services" content="Digiblocks | Web Development Services" />
        <title>Digiblocks | Web Development Services</title>
      </Helmet>
      <ChakraProvider>
        <Header />
        <Backtotop />
        <ContactFloating />
      </ChakraProvider>
      <Top />
      <WordCloud />
      <Mid />
      <Testimonials />
      <NewContact />
      <NewsLetter />
      <Footer />
    </div>
  );
};
export default DevelopmentPage;

import { Box, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import Backtotop from "../Backtotop/Backtotop";

const TermsAndConditions = (e) => {
  let handleDrag = (e) => {
    e.preventDefault();
  };
  
  return (
    <>
      <Box userSelect={"none"}>
        <Backtotop />
        <Image
          onDragStart={handleDrag}
          src="https://mbaroi.in/img/terms-banner.jpg"
          alt="img"
          h={["190px", "240px", "400px"]}
          w={"100%"}
        ></Image>
        <Box p={"20px"} width={"90%"} margin={"auto"} mt={"2%"} mb={"5%"} bg="#fef6f6">
          {/* Heading 1 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Name Availability
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />
          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Name availability application on the website is for the convenience
            of the user only. It does not guarantee that the specific name which
            has been entered by the user and which has been shown available to
            the user by the website will be assigned to the user only. That
            specific available name should be subject to the Ministry of
            Corporate Affairs and IP India approval only.
          </Text>

          {/* Heading 2 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            User Guidelines
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            The users of Digiblocks are granted a nonexclusive, limited right to
            access and use the service in accordance with the rules that are
            described in this contract. In order to keep this system attractive
            and useful for all users, it is important that users follow the
            rules of the system. Digiblocks reserves the right to deny further
            access to its service to any user who violates these rules, is the
            subject of complaints by other Digiblocks users or for any other
            reason.
          </Text>

          {/* Heading 3 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Violate Internet Standards
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Use the service for displaying harassing, abusive, threatening,
            harmful, vulgar, obscene, or tortuous material or invading other's
            privacy. Interfere with or disrupting the service or servers or
            networks connected to the service by posting advertisements or links
            to competing services, transmitting "junk mail", "spam", "chain
            letters", or unsolicited mass distribution of e-mail. Compromise the
            security of the service Digiblocks provides. Please do not try to
            gain access to system areas private to Digiblocks , or to other
            users.
          </Text>

          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Limitations on Use
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            The contents of Digiblocks are for personal use only and not for
            commercial exploitation. You may not decompile, reverse engineer,
            disassemble, rent, lease, loan, sell, sublicense, or create
            derivative works from Digiblocks. Nor may you use any network
            monitoring or discovery software to determine the site architecture,
            or extract information about usage or users. You may not use any
            robot, spider, other automatic device, or manual process to monitor
            or copy the contents without taking prior written permission from
            Digiblocks.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Confidentiality
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks makes every effort to maintain the confidentiality of any
            information submitted by users to our system and our database of
            lawyers. The user is however warned that the use of the internet or
            e-mail for confidential or sensitive information is susceptible to
            risks that inevitably arise on this medium.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Indemnification
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            The user agrees that Digiblocks is not responsible for any harm that
            his/her use of this service may cause. The user agrees to indemnify,
            defend, and hold Digiblocks harmless from and against any and all
            liability and costs incurred in connection with any loss, liability,
            claim, demand, damage, and expenses arising from or in connection
            with the contents or use of the service. The user agrees that this
            defence and indemnity shall also apply to any breach by the user of
            the agreement or the foregoing representations, warranties and
            covenants. The user further agrees that this defence and indemnity
            shall include without limitation lawyer fees and costs. The user
            also agrees that this defence and indemnity shall apply to
            Digiblocks, its founders, officers and employees. Digiblocks
            reserves the right, at its own expense, to assume the exclusive
            defence and control of any matter otherwise subject to
            indemnification by the user and the user shall not in any event
            settle any matter without the written consent of Digiblocks.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Communications and Other Data
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks is not responsible for any loss of data resulting from
            accidental or deliberate deletion, network or system outages, backup
            failure, file corruption, or any other reasons. We do not assume any
            liability for any content posted by anyone on our website or through
            any e-mail communication.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            License of your contents to Digiblocks
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            By uploading content to or submitting any materials for use on
            Digiblocks, you grant (or warrant that the owner of such rights has
            expressly granted) Digiblocks a perpetual, royalty-free,
            irrevocable, non-exclusive right and license to use, reproduce,
            modify, adapt, publish, translate, create derivative works from and
            distribute such materials or incorporate such materials into any
            form, medium, or technology now known or later developed. Digiblocks
            however gives an assurance that any information of a sensitive
            nature will not be intentionally disclosed and revealed to any third
            party.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Digiblocks Proprietary Rights
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Except as expressly provided in these terms and conditions, nothing
            contained herein shall be construed as conferring any license or
            right, by implication, estoppels or otherwise, under copyright or
            other intellectual property rights. The user agrees that the content
            and Web Site are protected by copyright, trademark, service marks,
            patents or other proprietary rights and laws. The user acknowledges
            and agrees that the user is permitted to use this material and
            information only as expressly authorized by Digiblocks, and may not
            copy, reproduce, transmit, distribute, or create derivative works of
            such content or information without express authorization. The user
            acknowledges and agrees that Digiblocks can display images and text
            throughout the Service. Users cannot extract and publish any
            information from the system, either electronically or in print,
            without the permission of Digiblocks and the permission of all other
            concerned parties. This is not a complete list - other things on the
            system are also Digiblocks property. Contact Digiblocks before
            copying anything from the system with plans of reproducing it or
            distributing it.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Linking to Digiblocks.in
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Users are welcome to provide links to the homepage of Digiblocks,
            provided they do not remove or obscure, by framing or otherwise, any
            portion of the homepage and that you discontinue providing links to
            the site if requested by Digiblocks.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Advertisers
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks may contain advertising and sponsorship. Advertisers and
            sponsors are responsible for ensuring that material submitted for
            inclusion on Digiblocks is accurate and complies with applicable
            laws. Digiblocks will not be responsible for the illegality of or
            any error or inaccuracy in advertisers' or sponsors' materials.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Registration
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Certain sections of Digiblocks may require you to register. If
            registration is requested, you agree to provide Digiblocks with
            accurate and complete registration information. It is your
            responsibility to inform Digiblocks of any changes to that
            information. Each registration is for a single person only, unless
            specifically designated otherwise on the registration page.
            Digiblocks does not permit a) any other person using the registered
            sections under your name; or b) access through a single name being
            made available to multiple users on a network. You are responsible
            for preventing such unauthorized use. If you believe there has been
            unauthorized use, please notify Digiblocks immediately by contacting
            us. If we find that unauthorized use is being made of Digiblocks and
            the services we provide, the right of any or many users may be
            terminated.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Errors and Corrections
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks does not represent or warrant that the service will be
            error-free, free of viruses or other harmful components, or that
            defects will be corrected. Digiblocks may make improvements and/or
            changes to its features, functionality or service at any time.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Third Party Content
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Third party content may appear on Digiblocks or may be accessible
            via links from Digiblocks. Digiblocks is not responsible for and
            assumes no liability for any mistakes, misstatements of law,
            defamation, slander, libel, omissions, falsehood, obscenity or
            profanity in the statements, opinions, representations or any other
            form of information contained in any third-party content appearing
            on Digiblocks. You understand that the information and opinions in
            the third party content is neither endorsed by nor does it reflect
            the belief of Digiblocks.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Unlawful Activity
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks reserves the right to investigate complaints or reported
            violations of the Agreement and to take any action Digiblocks deems
            appropriate including but not limited to reporting any suspected
            unlawful activity to law enforcement officials, regulators, or other
            third parties and disclosing any information necessary or
            appropriate to such persons or entities relating to user profiles,
            e-mail addresses, usage history, posted materials, IP addresses and
            traffic information.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Remedies for Violations
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks reserves the right to seek all remedies available at law
            and in equity for violations of the Agreement, including but not
            limited to the right to block access from a particular Internet
            address to Digiblocks and its features.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Severability of Provisions
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            The Agreement and the Privacy Policy constitute the entire agreement
            with respect to the use of the service provided by Digiblocks. If
            any provision of these terms and conditions is unlawful, void or
            unenforceable then that provision shall be deemed severable from the
            remaining provisions and shall not affect their validity and
            enforceability.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Modifications to Terms of Use
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks may change the agreement at any time. The user will be
            responsible for checking the Terms and Conditions before use. Use of
            the service after the hange will indicate acceptance of the new
            terms and conditions.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Modifications to Service
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Digiblocks reserves the right to modify or discontinue, temporarily
            or permanently, the service with or without notice to the user. The
            user agrees that Digiblocks shall not be liable to the user or any
            third party for any modification or discontinuance of the service.
            The user acknowledges and agrees that any termination of service
            under any provision of this agreement may be effected without prior
            notice, and acknowledges and agrees that Digiblocks may immediately
            delete data and files in the user's account and bar any further
            access to such files or the Service.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Disclaimer of Warranties and Limitation of Liability
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            A great danger for Digiblocks, and for all operators of online
            systems, is that we might be held accountable for the wrongful
            actions of our users. If one user libels another user, the injured
            user might blame us, even though the first user was really at fault.
            If a user uploads a program with a computer virus, and the other
            users' computers are damaged, we might be blamed even though a user
            left the virus on our System. If a user transmits illegal or
            improper information to another user, we might be blamed even though
            we did nothing more than unknowingly carry the message from one user
            to another. Accordingly, we need all users to accept responsibility
            for their own acts, and to accept that an act by another user that
            damages them must not be blamed on us, but only on the other user.
          </Text>
          <br></br>
          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Although it is the goal of Digiblocks to provide users with reliable
            and quality systems, we may make mistakes or experience system
            failure from time to time. Such problems are inevitable in operating
            a system of this size. We would not be able to make this system
            available to users if we had to accept blame or financial liability
            for any usability problems, system failures or errors, or mistakes
            or damages of any kind. In order to continue offering and improving
            our service, Digiblocks must deny any warranties on this service and
            state that our liability for any problems connected with the use of
            our system or any communication through Digiblocks is strictly
            limited to the amount paid to Digiblocks by the user.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Disclaimer of Warranties
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            The user expressly agrees that use of the service is at the user's
            sole risk. The service is provided on an "as is" and "as available"
            basis. Digiblocks expressly disclaims all warranties of any kind,
            whether express or implied, including, but not limited to the
            implied warranties of merchantability, fitness for a particular
            purpose and non- infringement. Digiblocks makes no warranty that the
            service will meet a user's requirements, that the service will be
            uninterrupted, timely, secure, or error-free; nor does Digiblocks
            make any warranty as to the results that may be obtained from the
            use of the service or as to the accuracy or reliability of any
            information obtained through the service or that defects in the
            software will be corrected. Digiblocks makes no warranty regarding
            any goods or services purchased or information obtained through the
            service or any transactions entered into through the service. o
            advice or information, whether oral or written, obtained by the User
            from Digiblocks shall create any warranty not expressly stated
            herein.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Limitation of Liability
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            The user agrees that Digiblocks shall not be liable for any direct,
            indirect, incidental, special or consequential damages resulting
            from the use or the inability to use the service or for the cost of
            procurement of substitute goods and services or resulting from any
            goods or services purchased or obtained or messages received or
            transactions entered into through or from the service or resulting
            from unauthorized access to or alteration of user's transmissions or
            data, including, but not limited to damages for loss of profits,
            use, data or other intangibles, even if Digiblocks has been advised
            of the possibility of such damages. The user further agrees that
            Digiblocks shall not be liable for any damages arising from
            interruption, suspension or termination of service, including, but
            not limited to direct, indirect, incidental, special, consequential
            or exemplary damages, whether or not such interruption, suspension
            or termination was justified, negligent, intentional or inadvertent.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Arbitration
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            Any controversy or claim arising out of or relating to this
            Agreement or Digiblocks services shall be settled by binding
            Arbitration in accordance with laws of India. Any such controversy
            or claim shall be arbitrated on an individual basis, and shall not
            be consolidated in any arbitration with any claim or controversy of
            any other party. Any other dispute or disagreement of a legal nature
            will also be decided in accordance with the laws of India, and the
            Courts of Haryana shall have jurisdiction in all such cases.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Ownership
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            This Site is owned and operated by Digiblocks. All right, title and
            interest in and to the materials provided on this Site, including
            but not limited to information, documents, logos, graphics, sounds
            and images (the "Materials") are owned by Digiblocks. Except as
            otherwise expressly provided by Digiblocks, none of the materials
            may be copied, reproduced, republished, downloaded, uploaded,
            posted, displayed, transmitted or distributed in any way and nothing
            on this Site shall be construed to confer any license under any of
            Digiblocks intellectual property rights, whether by estoppel,
            implication or otherwise. Contact us if you have any questions about
            obtaining such licenses. Digiblocks does not sell, license, lease or
            otherwise provide any of the materials other than those specifically
            identified as being provided by Digiblocks. Any rights not expressly
            granted herein are reserved by Digiblocks.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Entire Agreement
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            This agreement constitutes the entire and whole agreement between
            user and Digiblocks, and is intended as a complete and exclusive
            statement of the terms of the agreement. This agreement shall
            supersede all other communications between Digiblocks and its users
            with respect to the subject matter hereof and supersedes and
            replaces all prior or contemporaneous understandings or agreements,
            written or oral, regarding such subject matter. If at any time you
            find these Terms and Conditions unacceptable or if you do not agree
            to these Terms and Conditions, please do not use this Site. We may
            revise these Terms and Conditions at any time without notice to you.
            It is your responsibility to review these Terms and Conditions
            periodically. By using Digiblocks services or accessing the
            Digiblocks site, you acknowledge that you have read these terms and
            conditions and agree to be bound by them.
          </Text>
          {/* Heading 4 */}
          <Heading textAlign={"center"} as="h2" size="lg" mt={6} mb={4}>
            Indemnification
          </Heading>
          <hr
            style={{ width: "30%", border: "3px solid #ef6042", margin: "auto" }}
          />

          <Text p={"10px"} fontSize={["16px", "17px", "18px"]}>
            You agree to defend, indemnify and hold harmless Digiblocks, our
            officers, directors, shareholders, employees and agents from and
            against any and all claims, liabilities, damages, losses or
            expenses, including reasonable attorneys' fees and costs, arising
            out of or in any way connected with your access to or use of the
            site and the materials.
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default TermsAndConditions;

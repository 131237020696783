import { Box, ChakraProvider, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import app from '../../assets/app2.jpg';
import web from '../../assets/web-design2.jpg';
import ecom from '../../assets/ecom2.jpg';
import blockchain from '../../assets/blockchain.jpg';
import seo from '../../assets/seo.jpg';
import b2b from '../../assets/B2B.jpg';
import b2c from '../../assets/b2c.jpg'

function Mid() {

    const sectionStyles = {
        transition: "transform 0.2s",
        cursor: "pointer",
        position: "relative",
        _hover: {
            transform: "scale(1.02)",
        },
    };

    return (
        <ChakraProvider>
            <Container maxW="container.xl" p={0}>
                <Flex direction="column" align="center">
                    {/* E-commerce */}
                    <Box
                        sx={sectionStyles}
                        bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={{ base: '6%', md: '6%' }}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            align="center"
                        // pb={{ base: 4, md: 12 }}
                        >
                            <Box flex={{ base: 1, md: 2, lg: 2, xl: 3 }} pr={{ md: 20 }}>
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    E-commerce
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    In the digital age, having a robust ecommerce platform is essential for any business. At Digiblocks, we specialize in developing customized ecommerce solutions tailored to your specific requirements. From secure payment gateways to seamless inventory management, we can help you create a seamless online shopping experience for your customers.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={ecom}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>

                    {/* Progressive Web Apps (PWA) */}
                    <Box
                        sx={sectionStyles}
                        // bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={16}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row-reverse' }}
                            align="center"
                        // pb={{ base: 4, md: 12 }}
                        >
                            <Box
                                flex={{ base: 1, md: 2, lg: 2, xl: 3 }}
                                pl={{ md: 20 }}
                            >
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    Progressive Web Apps (PWA)
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    Progressive Web Apps (PWA) combine the best of both web and mobile applications. Our team of experts can develop fast, reliable, and engaging PWAs that offer a native app-like experience across all devices. With offline capabilities and push notifications, PWAs can help you reach your audience anytime, anywhere.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={app}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>

                    {/* Blockchain Solutions */}
                    <Box
                        sx={sectionStyles}
                        bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={16}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            align="center"
                        // pb={{ base: 4, md: 12 }}
                        >
                            <Box
                                flex={{ base: 1, md: 2, lg: 2, xl: 3 }}
                                pr={{ md: 20 }}
                            >
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    Blockchain Solutions
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    Blockchain technology has the potential to revolutionize various industries. At Digiblocks, we specialize in developing customized blockchain solutions that can streamline your business operations and enhance security. From smart contracts to decentralized applications, we have the expertise to meet your blockchain needs.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={blockchain}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>

                    {/* SEO Optimization */}
                    <Box
                        sx={sectionStyles}
                        // bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={16}
                        pb={32}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row-reverse' }}
                            align="center"
                        >
                            <Box
                                flex={{ base: 1, md: 2, lg: 2, xl: 3 }}
                                pl={{ md: 20 }}
                            >
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    SEO Optimization
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    In today's competitive digital landscape, it is crucial to be visible to your target audience. Our team of SEO experts will optimize your website to ensure that it ranks higher in search engine results, driving organic traffic and maximizing your online visibility.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={seo}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>


                    {/* B2B Section */}
                    <Box
                        sx={sectionStyles}
                        bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={16}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            align="center"
                        // pb={{ base: 4, md: 12 }}
                        >
                            <Box flex={{ base: 1, md: 2, lg: 2, xl: 3 }} pr={{ md: 20 }}>
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    B2B Solutions
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    Your business-to-business (B2B) solutions are crucial. We specialize in tailoring B2B solutions to meet your specific requirements, ensuring efficient communication and collaboration with your partners and clients.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={b2b}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>

                    {/* B2C Section */}
                    <Box

                        sx={sectionStyles}
                        // bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={16}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row-reverse' }}
                            align="center"
                        // pb={{ base: 4, md: 12 }}
                        >
                            <Box flex={{ base: 1, md: 2, lg: 2, xl: 3 }} pl={{ md: 20 }}>
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    B2C Solutions
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    Engaging with your customers directly (B2C) is key to your success. We create B2C solutions that ensure an excellent customer experience and drive your business growth. Connect with your audience anytime, anywhere.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={b2c}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>

                    {/* Stunning Design */}
                    <Box

                        sx={sectionStyles}
                        bgColor="#fef6f6"
                        p={{ base: 4, md: 8, lg: 10, xl: 12 }}
                        rounded="lg"
                        shadow="md"
                        mt={16}
                    >
                        <Flex
                            direction={{ base: 'column', md: 'row' }}
                            align="center"
                        // pb={{ base: 4, md: 12 }}
                        >
                            <Box
                                flex={{ base: 1, md: 2, lg: 2, xl: 3 }}
                                pr={{ md: 20 }}
                            >
                                <Heading
                                    as="h1"
                                    fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
                                    color="gray.800"
                                    mb={4}
                                >
                                    Stunning Design
                                </Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="black">
                                    We believe that design is not just about aesthetics but also functionality. Our team of talented designers will create a stunning and user-friendly website that reflects your brand identity and engages your target audience. With a focus on user experience, we ensure that every element of your website is optimized for maximum impact.
                                </Text>
                                <Flex alignItems="center" mt={4} cursor="pointer">
                                    <Link to="/contact">
                                        <Text fontSize="md" fontWeight="bold" color="#ef6042">
                                            Enquire Now <span aria-hidden="true">→</span>
                                        </Text>
                                    </Link>
                                </Flex>
                            </Box>
                            <Box
                                flex={{ base: 1, md: 1, lg: 1, xl: 3 }}
                                h={{ base: 'auto', md: '96' }}
                                pos="relative"
                            >
                                <Image
                                    src={web}
                                    alt="img"
                                    className="absolute w-full h-full inset-0 object-cover object-center rounded-md"
                                />
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Container>
        </ChakraProvider>
    );
}

export default Mid;

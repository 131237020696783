import React from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import img1 from "../../assets/img1.webp"
import img2 from "../../assets/img2.jpeg"
import img3 from "../../assets/img3.jpg"
import img4 from "../../assets/img4.jpg"
import img5 from "../../assets/img5.jpg"

export default function Testimonials() {
    return (
        <div className="lg:px-16 lg:mx-16">
            <div className="flex items-center justify-between w-full absolute z-0">
                <div className="w-1/3 bg-white" />
                <div className="w-4/6 ml-16 bg-[#fef6f6]" />
            </div>
            <div className="xl:px-5 px-8 py-20 2xl:mx-auto 2xl:container relative z-40">
                <CarouselProvider naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={5}>
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
                        What our clients are saying ?
                    </h1>

                    <Slider>
                        <Slide index={0} tabIndex="null">
                            <div className="flex">
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src={img1} alt="image of profile" className="w-full  flex-shrink-0 object-fit object-cover shadow-lg rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-white rounded-full">
                                            <img src="https://media0.giphy.com/media/3lDuP4LfRYTYJ6wIqt/giphy.gif" alt="commas" fill="red" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">Exceptional Web Design Work!</h1>
                                            <p className="text-base font-medium leading-6 mt-4 text-gray-600">Our experience with Digiblocks' web design services has been remarkable. Their commitment to our core values is evident in their work. They consistently prioritize our customers, and their attention to detail truly sets them apart.</p>
                                        </div>
                                        <div className="md:mt-0 mt-8">
                                            <p className="text-base font-medium leading-4 text-gray-800">Amit Patel</p>
                                            <p className="text-base leading-4 mt-2 mb-4 text-gray-600">Creative Director</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className="flex">
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src={img2} alt="img" className="w-full h-100 flex-shrink-0 object-fit object-cover shadow-lg rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-white rounded-full">
                                            <img src="https://media0.giphy.com/media/3lDuP4LfRYTYJ6wIqt/giphy.gif" alt="commas" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">Outstanding Web Development Services!</h1>
                                            <p className="text-base font-medium leading-6 mt-4 text-gray-600">Our experience with Digiblocks' web development services has been exceptional. They consistently deliver top-notch quality and outstanding service, making them the best choice for our digital needs.</p>
                                        </div>
                                        <div className="md:mt-0 mt-8">
                                            <p className="text-base font-medium leading-4 text-gray-800">Rajesh Sharma,</p>
                                            <p className="text-base leading-4 mt-2 mb-4 text-gray-600">Chief Technology Officer</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={2}>
                            <div className="flex">
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src={img3} alt="img" className="w-full h-100 flex-shrink-0 object-fit object-cover shadow-lg rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-white rounded-full">
                                            <img src="https://media0.giphy.com/media/3lDuP4LfRYTYJ6wIqt/giphy.gif" alt="commas" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">Outstanding service and expertise!</h1>
                                            <p className="text-base font-medium leading-6 mt-4 text-gray-600">Digiblocks has provided us with outstanding service and expertise. Their team is highly skilled, and they always go above and beyond to meet our needs.</p>
                                        </div>
                                        <div className="md:mt-0 mt-8">
                                            <p className="text-base font-medium leading-4 text-gray-800">Priya Patel</p>
                                            <p className="text-base leading-4 mt-2 mb-4 text-gray-600">Business Owner</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={3}>
                            <div className="flex">
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src={img4} alt="image of profile" className="w-full h-100 flex-shrink-0 object-fit object-cover shadow-lg rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-white rounded-full">
                                            <img src="https://media0.giphy.com/media/3lDuP4LfRYTYJ6wIqt/giphy.gif" alt="commas" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">Top-notch quality and support!</h1>
                                            <p className="text-base font-medium leading-6 mt-4 text-gray-600">Digiblocks consistently delivers top-notch quality and support. Their dedication to excellence is evident in every project they undertake.</p>
                                        </div>
                                        <div className="md:mt-0 mt-8">
                                            <p className="text-base font-medium leading-4 text-gray-800">Raj Sharma</p>
                                            <p className="text-base leading-4 mt-2 mb-4 text-gray-600">IT Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide index={4}>
                            <div className="flex">
                                <div className="mt-14 md:flex">
                                    <div className="relative lg:w-1/2 sm:w-96 xl:h-96 h-80">
                                        <img src={img5} alt="image of profile" className="w-full h-full flex-shrink-0 object-fit object-cover shadow-lg rounded" />
                                        <div className="w-32 md:flex hidden items-center justify-center absolute top-0 -mr-16 -mt-14 right-0 h-32 bg-white rounded-full">
                                            <img src="https://media0.giphy.com/media/3lDuP4LfRYTYJ6wIqt/giphy.gif" alt="commas" />
                                        </div>
                                    </div>
                                    <div className="md:w-1/3 lg:w-1/3 xl:ml-32 md:ml-20 md:mt-0 mt-4 flex flex-col justify-between">
                                        <div>
                                            <h1 className="text-2xl font-semibold xl:leading-loose text-gray-800">Exceptional service and creativity!</h1>
                                            <p className="text-base font-medium leading-6 mt-4 text-gray-600">We are continually impressed by Digiblocks' exceptional service and creativity. Their innovative solutions have greatly benefited our business.</p>
                                        </div>
                                        <div className="md:mt-0 mt-8">
                                            <p className="text-base font-medium leading-4 text-gray-800">Sneha Verma</p>
                                            <p className="text-base leading-4 mt-2 mb-4 text-gray-600">Marketing Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                    </Slider>
                    <div className="flex items-center mt-8">
                        <ButtonBack className="cursor-pointer " role="button" aria-label="previous slide">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonal-svg2.svg" alt="previous" />
                        </ButtonBack>

                        <ButtonNext role="button" aria-label="next slide" className="cursor-pointer ml-2">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg3.svg" alt="next" />
                        </ButtonNext>
                    </div>
                </CarouselProvider>
            </div>
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Stack,
  Text,
  VStack,
  HStack,
  Spacer,
  Container,
  IconButton,
  Input,
  useDisclosure,
  useToast,
  CircularProgress,
  Grid,
  Textarea,
  Flex,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  ButtonGroup,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react';
import axios from 'axios';
import { EditIcon, DeleteIcon, EmailIcon, SearchIcon } from '@chakra-ui/icons';
import ExportToExcelButton from './ExportToExcelButton';

const ContactPage = () => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [sortByRecent, setSortByRecent] = useState(false);

  const toast = useToast();
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [messageRecipient, setMessageRecipient] = useState('');

  const openSendMessageModal = (contact) => {
    setMessageRecipient(contact.email);
    setMessageContent('');
    setSelectedContact(contact);
    setIsSendingMessage(true);
  };

  // Function to toggle the status of a contact
  const handleToggleStatus = async (contact) => {
    try {
      const updatedContact = { ...contact, status: !contact.status };

      // Make an API request to update the contact's status
      await axios.put(`https://db-be-hheq.onrender.com/contacts/${contact._id}`, updatedContact);

      // Update the contacts in the state with the new status
      setContacts((prevContacts) =>
        prevContacts.map((c) =>
          c._id === contact._id ? updatedContact : c
        )
      );

      setFilteredContacts((prevFilteredContacts) =>
        prevFilteredContacts.map((c) =>
          c._id === contact._id ? updatedContact : c
        )
      );

      // Display a success toast
      toast({
        title: 'Contact status updated',
        description: `The status of ${contact.name} has been updated successfully.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating contact status:', error);

      // Display an error toast
      toast({
        title: 'Error updating contact status',
        description: 'An error occurred while updating the contact status.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [filterByStatus, setFilterByStatus] = useState('');

  // Function to filter contacts by status
  const handleFilterByStatus = (status) => {
    setFilterByStatus(status);
    if (status === 'all') {
      setFilteredContacts(contacts);
    } else {
      const filtered = contacts.filter((contact) =>
        status === 'resolved' ? contact.status : !contact.status
      );
      setFilteredContacts(filtered);
    }
  };
  useEffect(() => {
    axios
      .get('https://db-be-hheq.onrender.com/contacts')
      .then((response) => {
        const updatedContacts = response.data.map((contact) => ({
          ...contact,
        }));
        // console.log(response.data)
        setContacts(updatedContacts);
        setFilteredContacts(updatedContacts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching contacts:', error);
        setIsLoading(false);
      });
  }, []);

  const handleEditClick = (contact) => {
    setSelectedContact(contact);
    onOpen();
  };

  const handleDeleteClick = async (contact) => {
    try {
      await axios.delete(`https://db-be-hheq.onrender.com/contacts/${contact._id}`);
      setContacts((prevContacts) =>
        prevContacts.filter((c) => c._id !== contact._id)
      );
      setFilteredContacts((prevFilteredContacts) =>
        prevFilteredContacts.filter((c) => c._id !== contact._id)
      );

      toast({
        title: 'Contact deleted',
        description: 'The contact has been deleted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting contact:', error);

      toast({
        title: 'Error deleting contact',
        description: 'An error occurred while deleting the contact.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateContact = async (updatedContact) => {
    try {
      const response = await axios.put(
        `https://db-be-hheq.onrender.com/contacts/${updatedContact._id}`,
        updatedContact
      );

      const updatedContactData = response.data;

      setContacts((prevContacts) =>
        prevContacts.map((contact) =>
          contact._id === updatedContactData._id ? updatedContactData : contact
        )
      );

      setFilteredContacts((prevFilteredContacts) =>
        prevFilteredContacts.map((contact) =>
          contact._id === updatedContactData._id ? updatedContactData : contact
        )
      );

      onClose();

      toast({
        title: 'Contact updated',
        description: 'The contact has been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating contact:', error);

      toast({
        title: 'Error updating contact',
        description: 'An error occurred while updating the contact.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleSearch = () => {
    const filtered = contacts.filter(
      (contact) =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.phone.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredContacts(filtered);

    setIsSearching(searchTerm !== '');
  };

  const toggleSortByRecent = () => {
    if (sortByRecent) {
      setFilteredContacts(contacts);
    } else {
      const sorted = [...filteredContacts].sort((a, b) =>
        new Date(b.timestamp) - new Date(a.timestamp)
      );
      setFilteredContacts(sorted);
    }
    setSortByRecent(!sortByRecent);
  };

  const resetSearch = () => {
    setSearchTerm('');
    setIsSearching(false);
    setFilteredContacts(contacts);
  };

  // Function to send a message to a user
  const sendMessageToUser = async () => {
    try {
      // Make an API request to send the message
      await axios.post(`https://db-be-hheq.onrender.com/contacts/${selectedContact._id}/send-message`, {
        message: messageContent,
      });

      // Close the modal after sending the message
      setIsSendingMessage(false);

      // Display a success toast
      toast({
        title: 'Message sent to user',
        description: 'The message has been sent to the user successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error sending message to user:', error);

      // Display an error toast
      toast({
        title: 'Error sending message',
        description: 'An error occurred while sending the message to the user.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  return (
    <Container maxW="7xl">
      <div style={{display:"flex",  justifyContent:"space-between"}}>
        <Heading as="h1" fontSize="2xl" mt={8} mb={4}>
          Contact Requests
        </Heading>
        <ExportToExcelButton data={filteredContacts} />
      </div>


      <Stack spacing={4}>
        <InputGroup size="md" backgroundColor={"white"}>
          <InputLeftElement pointerEvents="none" >
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search by Name/Email/Phone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            color="gray.600"
            // focusBorderColor="teal.500"
            borderRadius="lg"
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputRightElement>
        </InputGroup>

        {isSearching && (
          <Button
            colorScheme="gray"
            variant="outline"
            size="sm"
            onClick={resetSearch}
            color="white"
            bg={'#ef6042'}
          >
            Reset
          </Button>
        )}

        <ButtonGroup spacing={4} isAttached variant="outline" colorScheme="teal">
          <Button
            size="md"
            onClick={() => handleFilterByStatus('all')}
            isActive={filterByStatus === 'all'}
            color={"blue"}
          >
            All
          </Button>
          <Button
            size="md"
            onClick={() => handleFilterByStatus('resolved')}
            isActive={filterByStatus === 'resolved'}
            color={"green"}
          >
            Resolved
          </Button>
          <Button
            size="md"
            onClick={() => handleFilterByStatus('pending')}
            isActive={filterByStatus === 'pending'}
            color={"orange"}
          >
            Pending
          </Button>

          <Switch
            isChecked={sortByRecent}
            onChange={toggleSortByRecent}
            size="md"
            colorScheme="red"
            margin={"10px"}
          >
            Sort by Recent
          </Switch>

        </ButtonGroup>



        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" minH="50vh">
            <CircularProgress isIndeterminate color="#ef6042" />
          </Flex>
        ) : (
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            }}
            gap={4}
          >
            {filteredContacts.map((contact) => (
              <Box
                key={contact._id}
                bg="white"
                p={4}
                borderRadius="20px"
                boxShadow="xl"
              >
                <VStack align="start">
                  <Text fontSize="lg" fontWeight="bold">
                    {contact.name}
                  </Text>
                  <Text fontSize="md">
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      Email:{' '}
                    </span>
                    {contact.email}
                  </Text>
                  <Text fontSize="md">
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      Mobile No:{' '}
                    </span>
                    {contact.phone}
                  </Text>
                  <Text fontSize="md" mt={2}>
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      Message:{' '}
                    </span>
                    {contact.message}
                  </Text>
                  <Text fontSize="md" style={{ fontWeight: 'bold' }}>
                    <span style={{ color: 'black' }}>Query Status: </span>
                    <span style={{ color: contact.status ? 'green' : 'orange' }}>
                      {contact.status ? 'Resolved' : 'Pending'}
                    </span>
                  </Text>

                </VStack>
                <Spacer />
                <HStack mt={4} spacing={4}>
                  <Text fontSize="sm" color="gray.500">
                    {new Date(contact.timestamp).toLocaleString()}
                  </Text>
                  <Switch
                    isChecked={contact.status}
                    onChange={() => handleToggleStatus(contact)}
                    size="md"
                    colorScheme="teal"
                  />
                  <IconButton
                    icon={<EditIcon />}
                    background={'transparent'}
                    aria-label="Edit Contact"
                    onClick={() => handleEditClick(contact)}
                    size={'lg'}
                    color="blue"
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    background={'transparent'}
                    aria-label="Delete Contact"
                    onClick={() => handleDeleteClick(contact)}
                    size={'lg'}
                    color="red"
                  />
                  <IconButton
                    icon={<EmailIcon />}
                    background={'transparent'}
                    aria-label="Send Message"
                    onClick={() => openSendMessageModal(contact)}
                    size={'lg'}
                    color="teal"
                  />
                </HStack>
              </Box>
            ))}
          </Grid>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <Stack spacing={4}>
                <input
                  type="text"
                  placeholder="Name"
                  value={selectedContact?.name}
                  onChange={(e) =>
                    setSelectedContact({
                      ...selectedContact,
                      name: e.target.value,
                    })
                  }
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={selectedContact?.email}
                  onChange={(e) =>
                    setSelectedContact({
                      ...selectedContact,
                      email: e.target.value,
                    })
                  }
                />
                <input
                  type="tel"
                  placeholder="Phone"
                  value={selectedContact?.phone}
                  onChange={(e) =>
                    setSelectedContact({
                      ...selectedContact,
                      phone: e.target.value,
                    })
                  }
                />
                <textarea
                  placeholder="Message"
                  value={selectedContact?.message}
                  onChange={(e) =>
                    setSelectedContact({
                      ...selectedContact,
                      message: e.target.value,
                    })
                  }
                />
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleUpdateContact(selectedContact)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSendingMessage} onClose={() => setIsSendingMessage(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Type your message here"
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsSendingMessage(false)}>
              Close
            </Button>
            <Button
              colorScheme="teal"
              onClick={sendMessageToUser}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ContactPage;

import React from "react";
import { Box, Center, ChakraProvider, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Typewriter from "typewriter-effect";
import iso from '../../assets/ISO.jpg';
import reg from '../../assets/registrations.jpg';
import dev from '../../assets/3323619.jpg'

const Servicecards = () => {
  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const navigate = useNavigate(); // Create a navigation function

  return (
    <ChakraProvider>
      <Box w={"85%"} m={"auto"} pt={"50px"}>
        <Text fontWeight={"700"} fontSize={["2xl", "3xl", "5xl"]}>
          <Typewriter
            options={{
              autoStart: true,
              autoload: true,
              delay: 100,
              strings: "Our Services ",
              cursor: " ",
            }}
          />
        </Text>

        <Center>
          <SimpleGrid columns={[1, 2, 3]} spacing={10}>
            <Box
              w={"100%"}
              p={"20px"}
              mt={"20px"}
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.05)" }}
              border={"1px solid #ef6042"}
              borderRadius={"20px"}
              onClick={() => navigate("/certificates")} // Navigate to CertificationPage
              style={{ cursor: "pointer" }}
            >
              <Image
                onDragStart={handleDragStart}
                src={iso}
                alt="img"
                w={"100%"}
              />
              <Text
                mt={"10px"}
                _hover={{ cursor: "pointer" }}
                fontSize={["18px", "20px", "22px"]}
                fontWeight={500}
              >
                Quality Certifications
              </Text>
              <Text
                mt={"10px"}
                color={"rgb(77,77,83)"}
                fontSize={["16px", "17px", "17px"]}
                maxW="400px"
                overflowWrap="break-word"

              >
                We offer a range of quality certifications that are customized to
                meet your business requirements and comply with the latest
                industry standards.
              </Text>
              <Text _hover={{ cursor: "pointer" }} color={'#ef6042'} mt={"20px"}>
                Explore Services →
              </Text>
            </Box>

            <Box
              w={"100%"}
              p={"20px"}
              mt={"20px"}
              border={"1px solid #ef6042"}
              borderRadius={"20px"}
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.05)" }}
              onClick={() => navigate("/registration")} // Navigate to RegistrationPage
              style={{ cursor: "pointer" }}
            >
              <Image
                onDragStart={handleDragStart}
                src={reg}
                alt="img"
                w={"100%"}
              />
              <Text
                mt={"10px"}
                _hover={{ cursor: "pointer" }}
                fontSize={["18px", "20px", "22px"]}
                fontWeight={500}
              >
                Registration Services
              </Text>
              <Text
                mt={"10px"}
                color={"rgb(77,77,83)"}
                fontSize={["16px", "17px", "17px"]}
                maxW="400px"
                overflowWrap="break-word"
              >
                We offer a comprehensive range of registration services that help
                you streamline your business operations and comply with relevant
                regulations
              </Text>
              <Text _hover={{ cursor: "pointer" }} color={'#ef6042'} mt={"20px"}>
                Learn More →
              </Text>
            </Box>

            <Box
              w={"100%"}
              p={"20px"}
              mt={"20px"}
              border={"1px solid #ef6042"}
              borderRadius={"20px"}
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.05)" }}
              onClick={() => navigate("/development")} // Navigate to DevelopmentPage
              style={{ cursor: "pointer" }}
            >
              <Image
                onDragStart={handleDragStart}
                src={dev}
                alt="img"
                w={"100%"}
              />
              <Text
                mt={"10px"}
                _hover={{ cursor: "pointer" }}
                fontSize={["18px", "20px", "22px"]}
                fontWeight={500}
              >
                Development
              </Text>
              <Text
                mt={"10px"}
                color={"rgb(77,77,83)"}
                fontSize={["16px", "17px", "17px"]}
                maxW="400px"
                overflowWrap="break-word"
              >
                We create visually appealing, user- friendly, and responsive
                websites that are optimized for search engines and functional on
                all devices.
              </Text>
              <Text _hover={{ cursor: "pointer" }} color={'#ef6042'} mt={"20px"}>
                Discover More →
              </Text>
            </Box>
          </SimpleGrid>
        </Center>
      </Box>
    </ChakraProvider>
  );
};

export default Servicecards;

import React from 'react';
import { ChakraProvider, Box, Flex, Text, Badge } from '@chakra-ui/react';

const words = [
    'Blockchain Solution',
    'E-commerce Development',
    'SEO Optimization',
    'User Experience',
    'Responsive Design',
    'Progressive Web Apps (PWA) ',
    'B2B',
    'B2C',
    'Stunning Design',
    'Customised Solutions',
    'Mobile App Development',
    'Web Development',

];

const WordCloud = () => {
    return (
        <ChakraProvider>
            <Flex
            userSelect={"none"}
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
                padding="2rem"
                backgroundColor={"#272829"}
            >
                {words.map((word, index) => (
                    <Box key={index} m="1rem">
                        <Badge
                            padding="0.5rem 1rem"
                            fontSize="1rem"
                            borderRadius="full"
                            bg="transparent"
                            border={"1px solid white"}
                            color="white"
                            boxShadow="md"
                        >
                            {word}
                        </Badge>
                    </Box>
                ))}
            </Flex>
        </ChakraProvider>
    );
};

export default WordCloud;

import React from 'react'
import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import Registration from '../components/RegistrationComponent/Registration';
import NewsLetter from '../components/FooterComponents/Newsletter';
import Footer from '../components/FooterComponents/Footer';
import Backtotop from '../components/Backtotop/Backtotop';
import ContactFloating from '../components/ContactFLoating/ContactFloating';
import { Helmet } from 'react-helmet';


const RegistrationPage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Helmet>
        <meta name="Digiblocks | IEC Registration" content="Digiblocks | IEC Registration" />
        <title>Digiblocks | IEC Registration</title>
      </Helmet>
      <ChakraProvider>
        <Header />
        <Backtotop />
        <ContactFloating />
        <Registration />
      </ChakraProvider>
      <NewsLetter />
      <Footer />
    </div>
  )
}

export default RegistrationPage

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg from '../../assets/blue-orange-cupboards_23-2148518393.jpg';

export default function Hero() {
  const [text, setText] = useState("");
  const sentences = [
    "Digiblocks is your go-to digital agency for quality certifications,",
    "development, and registration services. ",
    "Our user-friendly website makes it easy ",
    "to place orders and make inquiries about our services.",
  ];
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const typeText = () => {
      if (charIndex < sentences[sentenceIndex].length) {
        setText((prevText) => prevText + sentences[sentenceIndex][charIndex]);
        setCharIndex((prevIndex) => prevIndex + 1);
      } else if (sentenceIndex < sentences.length - 1) {
        // Move to the next sentence
        setSentenceIndex((prevIndex) => prevIndex + 1);
        setCharIndex(0);
      }
    };

    const typingInterval = setInterval(typeText, 50);

    return () => clearInterval(typingInterval);
  }, [charIndex, sentenceIndex]);


  return (
    <div style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div className="relative isolate  px-6 pt-14 lg:px-8">
        {/* Background elements */}
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20 bg-white">
              Want to learn more about certifications?{" "}
              <Link to="/certificates" className="font-semibold text-[#ef6042]">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Welcome to Digiblocks
            </h1>
            <p className="mt-6 text-md leading-8 text-black font-bold">{text}</p>

            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to="/certificates"
                className="rounded-full bg-white px-4 py-2 text-sm font-semibold text-[#ef6042] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-600"
              >
                Get started
              </Link>
              <Link
                to="/about"
                className="text-sm font-semibold leading-6 text-gray-900 hover:text-white"
              >
                Learn more <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';

const Backtotop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <IconButton
      icon={<FaArrowUp />}
      onClick={scrollToTop}
      isRound
      position="fixed"
      bottom="80px"
      p={"15px"}
      color={"white"}
      right="20px"
      borderRadius={"50%"}
      zIndex="1000"
      bg={"#ef6042"}
      _hover={{ bg: "#d94c32" }}
      size="lg"
      opacity={isVisible ? 1 : 0}
      transform={isVisible ? 'translateY(0)' : 'translateY(10px)'}
      transition="opacity 0.3s, transform 0.3s"
      
      colorScheme="orange"
    />
  );
};

export default Backtotop;

// Backtotop
import React, { useEffect } from 'react';
import {
  FaCertificate,
  FaLaptopCode,
  FaFileSignature,
  FaGlobe,
  FaHeadset,
  FaCheck,
  FaDollarSign,
  FaLightbulb,
  FaHandshake,
  FaClock,
} from 'react-icons/fa';
import AOS from 'aos';

import 'aos/dist/aos.css'; // You may need to adjust the path to the CSS file

const features = [
  {
    name: "Quality Certifications",
    description:
      "We offer a wide range of quality certifications to ensure your products meet the highest industry standards.",
    icon: FaCertificate,
  },
  {
    name: "Expert Development Team",
    description:
      "Our team of experienced developers is dedicated to creating customized solutions that align with your business needs.",
    icon: FaLaptopCode,
  },
  {
    name: "Seamless Registration Services",
    description:
      "Our hassle-free registration services simplify the process, allowing you to focus on growing your business.",
    icon: FaFileSignature,
  },
  {
    name: "User-Friendly Website",
    description:
      "Our user-friendly website provides easy access to our services and allows you to place orders effortlessly.",
    icon: FaGlobe,
  },
  {
    name: "Reliable Customer Support",
    description:
      "Our dedicated customer support team is available 24/7 to assist you with any inquiries or issues.",
    icon: FaHeadset,
  },
];

const benefits = [
  {
    name: "Easy Order Placement",
    description:
      "Our streamlined order placement process ensures a convenient and efficient experience for our customers.",
    icon: FaCheck,
  },
  {
    name: "Quality Services",
    description:
      "We are committed to delivering high-quality services that not only meet but exceed our customers' expectations.",
    icon: FaCertificate,
  },
  {
    name: "Affordable Pricing",
    description:
      "Our competitive pricing guarantees that you receive excellent value for your investment.",
    icon: FaDollarSign,
  },
  {
    name: "Expert Advice",
    description:
      "Our team of experts is ready to provide valuable advice to assist you in making well-informed decisions.",
    icon: FaLightbulb,
  },
  {
    name: "Timely Delivery",
    description:
      "We prioritize on-time delivery to ensure your projects stay on schedule and meet their deadlines.",
    icon: FaClock,
  },
];

export default function FeatureBenefitSection() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 100,
      once: true,
    });
  }, []);

  return (
    <div className="bg-[#fef6f6] mt-10 py-8 sm:py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h6
            className="text-xl font-semibold text-[#ef6042]"
            data-aos="fade-up"
          >
            Discover Our Features and Benefits
          </h6>
          <p
            className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-5xl"
            data-aos="fade-up"
          >
            Elevate Your Business with Digiblocks
          </p>
          <p
            className="mt-6 text-md leading-7 text-gray-600"
            data-aos="fade-up"
          >
            {`Digiblocks is your trusted partner for quality certifications, development, and registration services. Our commitment to excellence and customer satisfaction sets us apart.`}
          </p>
        </div>
        <div
          className="grid grid-cols-1 mt-12 gap-y-10 sm:grid-cols-2 sm:gap-x-8 lg:grid-cols-2 lg:gap-y-16"
          data-aos="fade-up"
        >
          {/* Features */}
          <div
            className="relative pl-8 p-6 border-dotted border-4 border-[#ef6042] rounded-lg"
            data-aos="fade-up"
          >
            <h3 className="text-2xl font-bold leading-6 text-gray-900">
              Features
            </h3>
            <dl className="mt-6 space-y-4">
              {features.map((feature, index) => (
                <div key={index}>
                  <dt className="flex items-center space-x-2 text-base font-bold leading-5 text-gray-900">
                    {<feature.icon className="h-6 w-6 text-[#ef6042]" />}
                    <span>{feature.name}</span>
                  </dt>
                  <dd className="mt-2 text-base leading-6 text-gray-600">
                    {feature.description}
                  </dd>
                  <br />
                </div>
              ))}
            </dl>
          </div>
          {/* Benefits */}
          <div
            className="relative pl-8 p-6 border-dotted border-4 border-[#ef6042] rounded-lg"
            data-aos="fade-up"
          >
            <h3 className="text-2xl font-bold leading-6 text-gray-900">
              Benefits
            </h3>
            <dl className="mt-6 space-y-4">
              {benefits.map((benefit, index) => (
                <div key={index}>
                  <dt className="flex items-center space-x-2 text-base font-bold leading-5 text-gray-900">
                    {<benefit.icon className="h-6 w-6 text-[#ef6042]" />}
                    <span>{benefit.name}</span>
                  </dt>
                  <dd className="mt-2 text-base leading-6 text-gray-600">
                    {benefit.description}
                  </dd>
                  <br />
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { CalendarDaysIcon, HandRaisedIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewsLetter() {
  const [email, setEmail] = useState('');

  const handleSubscribe = () => {
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address');
    } else {
      // Perform subscription logic here
      toast.success('Subscribed successfully!');
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return pattern.test(email);
  };

  return (
    <>
      <div className="relative isolate overflow-hidden bg-[#fef6f6] py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">
                Subscribe to Digiblocks Newsletter
              </h2>
              <p className="mt-4 text-lg leading-8 text-black-300">
                Stay updated with our latest news and services. Subscribe now!
              </p>
              <div className="mt-6 flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  onClick={handleSubscribe}
                  className="flex-none rounded-md bg-[#ef6042] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#d94c32] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Subscribe
                </button>
              </div>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
              <div className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <CalendarDaysIcon
                    className="h-6 w-6 text-black"
                    aria-hidden="true"
                  />
                </div>
                <dt className="mt-4 font-semibold text-black">
                  Weekly Updates
                </dt>
                <dd className="mt-2 leading-7 text-gray-700">
                  Get our latest articles and news delivered to your inbox every
                  week.
                </dd>
              </div>
              <div className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <HandRaisedIcon
                    className="h-6 w-6 text-black"
                    aria-hidden="true"
                  />
                </div>
                <dt className="mt-4 font-semibold text-black">No Spam</dt>
                <dd className="mt-2 leading-7 text-gray-700">
                  We respect your privacy and promise not to send you spam
                  emails.
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
          aria-hidden="true"
        >
          <div className="aspect-[1155/678] w-[72.1875rem]" />
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
}

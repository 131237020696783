import React, { useState } from "react";
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Avatar,
  VStack,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import {
  FiHome,
  FiFileText,
  FiUsers,
  FiPhone,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiFile,
} from "react-icons/fi";
import DashboardPage from "./DashboardPage";
import CertificatesList from "./SettingsPage";
import ContactPage from "./ContactPage";
import RegistrationPage from "./RegistrationPage";
import CertificatesPage from "./CertificatesPage";

import logo from "../../assets/Digiblocks (2).png";
import RegistrationList from "./RegistrationList";
import { useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import AdminLogin from "../../Pages/AdminLogin";

const LinkItems = [
  { name: "Dashboard", icon: FiHome },
  { name: "Certification-Req", icon: FiFileText },
  { name: "Registration-Req", icon: FiUsers },
  { name: "Contact Requests", icon: FiPhone },
  { name: "Certificates List", icon: FiFileText },
  { name: "Registration List", icon: FiFileText },
];


const SidebarContent = ({ onClose, onItemClick, ...rest }) => {
  return (
    <Flex
      bg={useColorModeValue("white", "gray.900")}
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "40%", md: 60 }}
      pos="fixed"
      h="full"
      flexDirection={"column"}
      fontWeight="semibold"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <img
          src={logo}
          alt="Digiblocks"
          style={{ maxWidth: "150px", maxHeight: "100px" }}
        />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          onClick={() => onItemClick(link.name)}
        >
          {link.name}
        </NavItem>
      ))}
    </Flex>
  );
};

const NavItem = ({ icon, children, onClick, ...rest }) => {

  return (
    <Box
      as="button"
      style={{ textDecoration: "none" }}
      onClick={onClick}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#ef6042",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  let navigate = useNavigate();
  let dologout = async () => {
    let val = await axios.post("https://db-be-hheq.onrender.com/adminlogin/do-logout");
    navigate("/admin-login");
  }
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Flex
        display={{ base: "flex", md: "none" }}
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={logo}
          alt="Digiblocks"
          style={{ maxWidth: "150px", maxHeight: "100px" }}
        />
      </Flex>

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar
                  size={"sm"}
                  src=''
                  alt="img"
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">Digiblocks</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem>Sign out</MenuItem>
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => {
                dologout()
              }}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState("");
  let [isAuth, setIsAuth] = useState(false)

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName);
    onClose();
  };
  let getauthstatus = async () => {
    let val = await axios.get("https://db-be-hheq.onrender.com/adminlogin/check-login");
    // console.log(val.data.isLoggedIn);
    setIsAuth(val.data.isLoggedIn);
  }
  useEffect(() => {
    getauthstatus()
  }, [isAuth])
  return isAuth ?
    <>
      <Box minH="100vh" bg={"#fef6f6"}>
        <SidebarContent onClose={() => onClose} onItemClick={handleItemClick} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          // size="full"
          width={"30%"}
        // border={"2px solid red"}

        >
          <DrawerContent >
            <SidebarContent onClose={onClose} onItemClick={handleItemClick} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />

        {/* Use a Flex container to make content full-width */}
        <Box ml={{ base: 0, md: 60 }}>
          {/* Conditionally render components based on selectedItem */}
          {selectedItem === "" && <DashboardPage />}
          {selectedItem === "Dashboard" && <DashboardPage />}
          {selectedItem === "Certification-Req" && <CertificatesPage />}
          {selectedItem === "Registration-Req" && <RegistrationPage />}
          {selectedItem === "Contact Requests" && <ContactPage />}
          {selectedItem === "Certificates List" && <CertificatesList />}
          {selectedItem === "Registration List" && <RegistrationList />}
        </Box>
      </Box>
    </> : <>
      <AdminLogin />
    </>
};

export default Sidebar;



import React, { useState, useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { IconButton, Flex, Input, Textarea, useToast, ModalOverlay, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Text, Button, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ContactFloating = () => {
  let navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Set loading state to true

    try {
      const response = await axios.post('https://db-be-hheq.onrender.com/contacts', {
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
        message: messageValue,
      });

      if (response.status === 200) {
        toast({
          title: "Message Sent",
          description: "Your message has been sent successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setNameValue("");
        setEmailValue("");
        setPhoneValue("");
        setMessageValue("");
        onClose();
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: "Error",
        description: "An error occurred while sending your message. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };

  return (
    <>
      <IconButton
        icon={<FaEnvelope />}
        onClick={() => {
          onOpen();
        }}
        isRound
        position="fixed"
        bottom="20px"
        right="20px"
        p="15px"
        color="white"
        borderRadius="50%"
        zIndex="1000"
        bg="#ef6042"
        size="lg"
        colorScheme="orange"
        _hover={{ bg: "#d94c32" }}
      />
      <Modal isCentered position="relative"  isOpen={isOpen} onClose={onClose}>
        <ModalContent position="absolute" bottom={0} right="0">
          <ModalHeader textAlign={"center"} fontWeight={700} fontSize={"22px"}>Enquire Now</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              direction="column"
              alignItems="center"
              gap={["20px", "30px", "40px"]}
              bg={"#fef6f6"}
              p={["10px", "20px", "20px"]}
              pt={"60px"}
              pb={"60px"}
            >
              <Input
                bg={"white"}
                placeholder="Name"
                width="90%"
                p={3}
                mb={3}
                border="1px solid gray.400"
                borderRadius="md"
                _focus={{ borderColor: "red.500" }}
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
              />
              <Input
                bg={"white"}
                placeholder="Email"
                width="90%"
                p={3}
                mb={3}
                border="1px solid gray.400"
                borderRadius="md"
                _focus={{ borderColor: "red.500" }}
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />
              <Input
                bg={"white"}
                placeholder="Phone"
                width="90%"
                p={3}
                mb={3}
                border="1px solid gray.400"
                borderRadius="md"
                _focus={{ borderColor: "red.500" }}
                value={phoneValue}
                onChange={(e) => setPhoneValue(e.target.value)}
              />
              <Textarea
                bg={"white"}
                placeholder="Message"
                width="90%"
                p={3}
                mb={4}
                border="1px solid gray.400"
                borderRadius="md"
                _focus={{ borderColor: "red.500" }}
                value={messageValue}
                onChange={(e) => setMessageValue(e.target.value)}
              />
              <Button
                type="submit"
                backgroundColor={"#ef6042"}
                _hover={{ bg: "#d94c32" }}
                color="white"
                width="90%"
                p={4}
                size="sm"
                borderRadius="md"
                onClick={handleSubmit}
              >
                {isLoading ? <Spinner size="sm" /> : "Send Message"} {/* Conditionally render spinner or text */}
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button size={"sm"} colorScheme='red' onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ContactFloating;


import { Heading, Box, SimpleGrid, AccordionPanel, AccordionButton, InputGroup, Input, InputRightElement, AccordionIcon, AccordionItem, Accordion, Select, Button, Flex, Text, Link, Skeleton } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MyContext } from '../../Context/Appcontext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Registration = () => {


    let navigate = useNavigate();
    const [array, setArray] = useState([]);
    let [data, setdata] = useState(array);
    let [temp, settemp] = useState(array);
    let [bool, setbool] = useState(false);

    let [expimp, setexpimp] = useState([]);
    let x1 = [];

    let [promotion, setpromotion] = useState([]);
    let x2 = []

    let [glob, setglobal] = useState([]);
    let x3 = []

    let [usa, setusa] = useState([]);
    let x4 = []

    let [business, setbusiness] = useState([]);
    let x5 = []


    let getdata = async () => {
        let val = await axios.get("https://db-be-hheq.onrender.com/registrationList");
        // console.log(val)
        setArray(val.data)

        // export import
        for (let i = 0; i <= 6; i++) {
            x1.push(val.data[i]);
        }
        setexpimp(x1);


        // promotion 
        for (let i = 7; i <= 35; i++) {
            x2.push(val.data[i]);
        }
        setpromotion(x2);


        // export import
        for (let i = 36; i <= 47; i++) {
            x3.push(val.data[i]);
        }
        setglobal(x3);


        // export import
        for (let i = 48; i <= 50; i++) {
            x4.push(val.data[i]);
        }
        setusa(x4);


        // export import
        for (let i = 51; i < val.data.length; i++) {
            x5.push(val.data[i]);
        }
        setbusiness(x5);

        setdata([{
            type: "EXPORT IMPORT REGISTRATIONS",
            data: expimp
        }, {
            type: "EXPORT PROMOTION COUNCIL REGISTRATIONS",
            data: promotion
        }, {
            type: "GLOBAL COMPANY REGISTRATION",
            data: glob
        }, {
            type: "USA REGISTRATION",
            data: usa
        }, {
            type: "BUSINESS REGISTRATIONS",
            data: business
        }])
        // console.log(data)
        setbool(!bool)
    }

    console.log(expimp, promotion, glob, usa, business)



    let { myData, setMyData } = useContext(MyContext);
    let btnref = useRef(null);

    let [filter, setfilter] = useState("");
    let [expandall, setExpandall] = useState(true);


    useEffect(() => {
        getdata()
        filterFunction();
    }, [filter]);

    const filterFunction = () => {
        if (filter !== "") {
            // setCurrentPage(1);
            let x = array.filter((e) => e && e.title && e.title.toLowerCase().includes(filter.toLowerCase()));
            if (x.length > 0)
                settemp(x);
            else {
                settemp([])
            }

        }
    };

    let [show1, setshow1] = useState(true);
    let [show2, setshow2] = useState(true);
    let [show3, setshow3] = useState(true);
    let [show4, setshow4] = useState(true);
    let [show5, setshow5] = useState(true);





    let setdataincontext = (e) => {
        setMyData(e);
        // console.log(e)
    }

    return (
        <Box p={"10px"} marginTop={["12%","12%","6%"]}>
            <Box>
                <>
                    {/* search */}
                    <Flex
                    direction="column"
                        flex={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                        m={"auto"}
                        p={"20px"}
                        w={"100%"}
                        gap={"20px"}
                    >
                        <InputGroup w={["80%", "60%", "50%"]} size="md">
                            <Input
                                onChange={(e) => {
                                    setfilter(e.target.value);
                                }}
                                pr="4.5rem"
                                placeholder="Search"
                                ref={btnref}
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    _hover={{ bg: "#d94c32" }}
                                    bg="#ef6042" color="white"
                                    onClick={() => {
                                        setfilter("");
                                        btnref.current.value = "";
                                    }}
                                >
                                    Reset
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {/* <Button onClick={filterFunction}>Search</Button> */}
                        <Button   w={["35%","35%%","10%"]} display={filter ? "none" : "block"}  bg="#ef6042" _hover={{ bg: "#d94c32" }} color="white" textAlign={"center"} onClick={() => {
                            setExpandall(!expandall)
                            if (expandall === false) {
                                setshow1(true);
                                setshow2(true);
                                setshow3(true);
                                setshow4(true);
                                setshow5(true);
                            } else {
                                setshow1(false);
                                setshow2(false);
                                setshow3(false);
                                setshow4(false);
                                setshow5(false);
                            }
                        }}>{expandall ? "Hide all" : "Expand all"}</Button>
                        <Text color={"red"}>{btnref.current && btnref.current.value != "" && temp.length == 0 ? "*No Matching results" : ""}</Text>
                    </Flex>
                    {
                        filter !== "" && temp.length > 0 ?
                            <>
                                <SimpleGrid columns={1} gap="20px" p={4}>
                                    {temp.length > 0 ? temp.map((e, index) => (
                                        <Accordion
                                            allowMultiple={false}
                                            key={index}
                                            allowToggle
                                            w={["80%", "80%", "50%"]}
                                            m="auto"
                                        >
                                            <AccordionItem>
                                                <Heading>
                                                    <AccordionButton
                                                        _expanded={{ bg: "#ef6042", color: "white" }}
                                                    >
                                                        <Box as="span" flex="1" textAlign="left">
                                                            {e.title}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </Heading>
                                                <AccordionPanel pb={4} textAlign={"left"}>
                                                    {e.description}
                                                    <Flex direction="column" alignItems="center" w="100%">
                                                        <Flex
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            w="100%"
                                                            mt={4}
                                                        >

                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"
                                
                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                    navigate("/registration")
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>

                                                            <Flex alignItems="center">

                                                            </Flex>
                                                        </Flex>
                                                        {/* Add other details as needed */}
                                                    </Flex>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    )) :
                                        <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>

                                            No Matching items found</Heading>}
                                </SimpleGrid>
                            </> :
                            <>
                                <Box>
                                    <Flex w={"90%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                        <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                            {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                            <Heading fontSize={["18px", "23px", "25px"]} p={"20px"}  >• EXPORT IMPORT REGISTRATIONS</Heading>

                                        </Box> <Button bg="#ef6042" _hover={{ bg: "#d94c32" }} color="white" onClick={() => {
                                            setshow1(!show1);
                                        }}>{show1 ? "-" : "+"}</Button>
                                    </Flex>
                                    <SimpleGrid display={show1 ? "grid" : "none"} columns={1} gap="20px" >
                                        <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                            <Box m={"auto"} w={"50rem"} textAlign={"left"} >


                                            </Box>
                                        </Flex>
                                        {

                                            expimp.length > 0 ?
                                                expimp.map((e, index) => (
                                                    <SimpleGrid display={show1 ? "grid" : "none"} columns={1} gap="20px" >
                                                        <Accordion
                                                            allowMultiple={false}
                                                            key={index}
                                                            allowToggle
                                                            w={["80%", "80%", "50%"]}
                                                            m="auto"
                                                        >
                                                            <AccordionItem>
                                                                <Heading>
                                                                    <AccordionButton
                                                                        _expanded={{ bg: "#ef6042", color: "white" }}
                                                                    >
                                                                        <Box as="span" flex="1" textAlign="left">
                                                                            {e.title}
                                                                        </Box>
                                                                        <AccordionIcon />
                                                                    </AccordionButton>
                                                                </Heading>
                                                                <AccordionPanel pb={4} textAlign={"left"}>
                                                                    {e.description}
                                                                    <Flex direction="column" alignItems="center" w="100%">
                                                                        <Flex
                                                                            justifyContent="space-between"
                                                                            alignItems="center"
                                                                            w="100%"
                                                                            mt={4}
                                                                        >
                                                                            <Button
                                                                                bg="#ef6042"
                                                                                _hover={{ bg: "#d94c32" }}
                                                                                color="white"
                                                                            
                                                                                onClick={() => {
                                                                                    setdataincontext(e)
                                                                                    navigate("/registration")
                                                                                }}
                                                                            >
                                                                                Apply
                                                                            </Button>

                                                                        </Flex>
                                                                        {/* Add other details as needed */}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>


                                                    </SimpleGrid>

                                                ))

                                                :<Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                                    </SimpleGrid>
                                </Box>

                                <Box>
                                    <Flex w={"90%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                        <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                            {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                            <Heading fontSize={["18px", "23px", "25px"]} p={"20px"}>• EXPORT PROMOTION COUNCIL REGISTRATIONS</Heading>

                                        </Box> <Button bg="#ef6042" _hover={{ bg: "#d94c32" }} color="white" onClick={() => {
                                            setshow2(!show2);
                                        }}>{show2 ? "-" : "+"}</Button>
                                    </Flex>
                                    <SimpleGrid display={show2 ? "grid" : "none"} columns={1} gap="20px" >
                                        <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                            <Box m={"auto"} w={"50rem"} textAlign={"left"} >


                                            </Box>
                                        </Flex>
                                        {

                                            promotion.length > 0 ?
                                                promotion.map((e, index) => (
                                                    <SimpleGrid display={show2 ? "grid" : "none"} columns={1} gap="20px" >
                                                        <Accordion
                                                            allowMultiple={false}
                                                            key={index}
                                                            allowToggle
                                                            w={["80%", "80%", "50%"]}
                                                            m="auto"
                                                        >
                                                            <AccordionItem>
                                                                <Heading>
                                                                    <AccordionButton
                                                                        _expanded={{ bg: "#ef6042", color: "white" }}
                                                                    >
                                                                        <Box as="span" flex="1" textAlign="left">
                                                                            {e.title}
                                                                        </Box>
                                                                        <AccordionIcon />
                                                                    </AccordionButton>
                                                                </Heading>
                                                                <AccordionPanel pb={4} textAlign={"left"}>
                                                                    {e.description}
                                                                    <Flex direction="column" alignItems="center" w="100%">
                                                                        <Flex
                                                                            justifyContent="space-between"
                                                                            alignItems="center"
                                                                            w="100%"
                                                                            mt={4}
                                                                        >
                                                                            <Button
                                                                                bg="#ef6042"
                                                                                color="white"
                                                                                _hover={{ bg: "#d94c32" }}
                                                                                onClick={() => {
                                                                                    setdataincontext(e)
                                                                                    navigate("/registration")
                                                                                }}
                                                                            >
                                                                                Apply
                                                                            </Button>

                                                                        </Flex>
                                                                        {/* Add other details as needed */}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>


                                                    </SimpleGrid>

                                                ))

                                                : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                                    </SimpleGrid>
                                </Box>

                                <Box>
                                    <Flex w={"90%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                        <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                            {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                            <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} >• GLOBAL COMPANY REGISTRATION</Heading>

                                        </Box> <Button  bg="#ef6042" _hover={{ bg: "#d94c32" }} color="white" 
                                        onClick={() => {
                                            setshow3(!show3);
                                        }}>{show3 ? "-" : "+"}</Button>
                                    </Flex>
                                    <SimpleGrid display={show3 ? "grid" : "none"} columns={1} gap="20px" >
                                        <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                            <Box m={"auto"} w={"50rem"} textAlign={"left"} >


                                            </Box>
                                        </Flex>
                                        {

                                            glob.length > 0 ?
                                                glob.map((e, index) => (
                                                    <SimpleGrid display={show3 ? "grid" : "none"} columns={1} gap="20px">
                                                        <Accordion
                                                            allowMultiple={false}
                                                            key={index}
                                                            allowToggle
                                                            w={["80%", "80%", "50%"]}
                                                            m="auto"
                                                        >
                                                            <AccordionItem>
                                                                <Heading>
                                                                    <AccordionButton
                                                                        _expanded={{ bg: "#ef6042", color: "white" }}
                                                                    >
                                                                        <Box as="span" flex="1" textAlign="left">
                                                                            {e.title}
                                                                        </Box>
                                                                        <AccordionIcon />
                                                                    </AccordionButton>
                                                                </Heading>
                                                                <AccordionPanel pb={4} textAlign={"left"}>
                                                                    {e.description}
                                                                    <Flex direction="column" alignItems="center" w="100%">
                                                                        <Flex
                                                                            justifyContent="space-between"
                                                                            alignItems="center"
                                                                            w="100%"
                                                                            mt={4}
                                                                        >
                                                                            <Button
                                                                                bg="#ef6042"
                                                                                _hover={{ bg: "#d94c32" }}
                                                                                color="white"
                                                                            
                                                                                onClick={() => {
                                                                                    setdataincontext(e)
                                                                                    navigate("/registration")
                                                                                }}
                                                                            >
                                                                                Apply
                                                                            </Button>

                                                                        </Flex>
                                                                        {/* Add other details as needed */}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>


                                                    </SimpleGrid>

                                                ))

                                                : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                                    </SimpleGrid>
                                </Box>

                                <Box>
                                    <Flex w={"90%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                        <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                            {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                            <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} >• USA REGISTRATION</Heading>

                                        </Box> <Button bg="#ef6042" _hover={{ bg: "#d94c32" }} color="white" onClick={() => {
                                            setshow4(!show4);
                                        }}>{show4 ? "-" : "+"}</Button>
                                    </Flex>
                                    <SimpleGrid display={show4 ? "grid" : "none"} columns={1} gap="20px" >
                                        <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                            <Box m={"auto"} w={"50rem"} textAlign={"left"} >


                                            </Box>
                                        </Flex>
                                        {

                                            usa.length > 0 ?
                                                usa.map((e, index) => (
                                                    <SimpleGrid display={show4 ? "grid" : "none"} columns={1} gap="20px" >
                                                        <Accordion
                                                            allowMultiple={false}
                                                            key={index}
                                                            allowToggle
                                                            w={["80%", "80%", "50%"]}
                                                            m="auto"
                                                        >
                                                            <AccordionItem>
                                                                <Heading>
                                                                    <AccordionButton
                                                                        _expanded={{ bg: "#ef6042", color: "white" }}
                                                                    >
                                                                        <Box as="span" flex="1" textAlign="left">
                                                                            {e.title}
                                                                        </Box>
                                                                        <AccordionIcon />
                                                                    </AccordionButton>
                                                                </Heading>
                                                                <AccordionPanel pb={4} textAlign={"left"}>
                                                                    {e.description}
                                                                    <Flex direction="column" alignItems="center" w="100%">
                                                                        <Flex
                                                                            justifyContent="space-between"
                                                                            alignItems="center"
                                                                            w="100%"
                                                                            mt={4}
                                                                        >
                                                                            <Button
                                                                                bg="#ef6042"
                                                                                color="white"
                                                                                _hover={{ bg: "#d94c32" }}
                                                                                onClick={() => {
                                                                                    setdataincontext(e)
                                                                                    navigate("/registration")
                                                                                }}
                                                                            >
                                                                                Apply
                                                                            </Button>

                                                                        </Flex>
                                                                        {/* Add other details as needed */}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>


                                                    </SimpleGrid>

                                                ))

                                                : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                                    </SimpleGrid>
                                </Box>

                                <Box>
                                    <Flex  w={"90%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                        <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                            {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                            <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} >• BUSINESS REGISTRATIONS</Heading>

                                        </Box> <Button bg="#ef6042" _hover={{ bg: "#d94c32" }} color="white" onClick={() => {
                                            setshow5(!show5);
                                        }}>{show5 ? "-" : "+"}</Button>
                                    </Flex>
                                    <SimpleGrid display={show5 ? "grid" : "none"} columns={1} gap="20px" >
                                        <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                            <Box m={"auto"} w={"50rem"} textAlign={"left"} >


                                            </Box>
                                        </Flex>
                                        {

                                            business.length > 0 ?
                                                business.map((e, index) => (
                                                    <SimpleGrid display={show5 ? "grid" : "none"} columns={1} gap="20px" >
                                                        <Accordion
                                                            allowMultiple={false}
                                                            key={index}
                                                            allowToggle
                                                            w={["80%", "80%", "50%"]}
                                                            m="auto"
                                                        >
                                                            <AccordionItem>
                                                                <Heading>
                                                                    <AccordionButton
                                                                        _expanded={{ bg: "#ef6042", color: "white" }}
                                                                    >
                                                                        <Box as="span" flex="1" textAlign="left">
                                                                            {e.title}
                                                                        </Box>
                                                                        <AccordionIcon />
                                                                    </AccordionButton>
                                                                </Heading>
                                                                <AccordionPanel pb={4} textAlign={"left"}>
                                                                    {e.description}
                                                                    <Flex direction="column" alignItems="center" w="100%">
                                                                        <Flex
                                                                            justifyContent="space-between"
                                                                            alignItems="center"
                                                                            w="100%"
                                                                            mt={4}
                                                                        >
                                                                            <Button
                                                                                bg="#ef6042"
                                                                                color="white"
                                                                                _hover={{ bg: "#d94c32" }}
                                                                                onClick={() => {
                                                                                    setdataincontext(e)
                                                                                    navigate("/registration")
                                                                                }}
                                                                            >
                                                                                Apply
                                                                            </Button>

                                                                        </Flex>
                                                                        {/* Add other details as needed */}
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>


                                                    </SimpleGrid>

                                                ))

                                                : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                                    </SimpleGrid>
                                </Box>
                                <Box><Text textAlign={"center"} mt={"20px"} mb={"20px"} fontWeight={"bolder"} fontSize={["22px","27px","30px"]}>And Many More...</Text></Box>




                            </>
                    }


                </>

            </Box>
        </Box>
    )
}

export default Registration






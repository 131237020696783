import { Box, Heading, Text, Container, ChakraProvider, Link } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import { Helmet } from "react-helmet";

const theme = extendTheme({
    colors: {
        brand: {
            primary: "#ef6062",
        },
    },
});

const PrivacyPolicy = () => {
    return (
        <ChakraProvider theme={theme}>
            <Helmet>
                <meta name="Digiblocks | Privacy Policy" content="Digiblocks | Privacy Policy" />
                <title>Digiblocks | Privacy Policy</title>
            </Helmet>
            <Header />
            <Container maxW="80%" margin={"auto"} pt={"10%"} mb={"5%"} centerContent>
                <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    boxShadow="lg"
                    bg="#fef6f6"
                    textAlign="left"

                >
                    <Heading as="h2" size="xl" mb={6} color="brand.primary">
                        Privacy Policy
                    </Heading>
                    <Text>
                        We at Digiblocks Private Limited (“we,” “us,” or “our”) have created this Privacy Policy (this "Privacy Policy") because we know that you value how the information and data you provide to us is collected, used, and shared. Digiblocks is the Controller for purposes of this Privacy Policy. This Privacy Policy applies to our online information collection and use practices when you visit the digiblocks.tech website (the “Site”), but does not relate to how we use the information you may share or provide to us offline or in connection with any services we may agree to provide in writing. The use of your information in such situations will be governed only through an executed master services agreement or other related documents with respect to the particular services. Your visit to the Site is also subject to our Terms of Use, which can be found <Link color="brand.primary" href="#">here</Link> and incorporates this Privacy Policy.
                    </Text>
                    <Text mt={6}>
                        <strong>Our Commitment to Privacy</strong>
                        <br />
                        We provide this Privacy Policy to make you aware of our privacy practices and of the choices you can make about the way your information is collected and used. This Privacy Policy describes what information we may collect about you; how we use your information; how we protect it; and what choices you have about how that information is used.
                    </Text>
                    <Text mt={6}>
                        We may revise any portion of the Site, including this Privacy Policy, from time to time to keep up with technical innovation, post new Site content, and for other reasons. All revisions to this Privacy Policy will be effective upon notice given by means of posting the revised Privacy Policy on the Site, with a new effective date posted at the top. If this Privacy Policy changes in a way that materially affects how we handle personal information, we will not use the Personal Information we previously gathered in the manner described in the new policy without providing notice and/or obtaining your consent, as appropriate. Your continued use of the Site after any revision(s) to this Privacy Policy will constitute your acceptance of the revised Privacy Policy. We encourage you to refer to this Privacy Policy frequently, and to regularly check the effective date shown at the top.
                    </Text>
                    <Text mt={6}>
                        <strong>Information We Collect from Website Users
                        </strong>
                        <br />
                        “Personal Information” includes information that can be used to identify a unique individual, such as a name, physical address, e-mail address, or telephone number. We collect Personal Information when you register for our news and events, or request a download of our case studies or white papers. We also collect Personal Information when you email or otherwise contact us through or regarding the Site.
                        We may also automatically collect information that includes Personal Information from all Site visitors, even if he or she does not register for the services discussed above. For example, we may use "cookies" or other technology to collect information about your use of the Site or emails we send to you. Cookies are described in more detail below. We also collect other information automatically, including: IP address, browser type, domain name, access times, duration of visit, referring URL, platform, new and repeat information, time stamp, and exit page information.
                        The Site may contain links to third party websites, advertisers, or services that are not owned or controlled by us. We are not responsible for how these websites operate or treat your Personal Information, so be sure to read such third party websites' terms and conditions, including their privacy policies.
                    </Text>
                    <Text mt={6}>
                        <strong> Use of the Information We Collect from Website Users</strong>
                        <br />
                        We may use your Personal Information internally for our own purposes, including to manage and maintain the Site, to provide or improve our services to you, to analyze your use of the Site, to study trends regarding use of the Site, to comply with our contractual obligations, or to fulfill other legitimate interests.
                        In addition, with your consent, we may use your Personal Information (for example, your email address) to send you certain marketing materials or mailings prepared by us containing news or other information. We also may use the Personal Information you provide to let you know when new content is available on the Site, or to alert you to conferences and other upcoming events, materials, goods, or services that we believe would be valuable to you and are consistent with our mission.
                        Subject to any applicable exceptions set forth in this Privacy Policy, we will delete your Personal Information once it is no longer required for the purposes set forth above in accordance with our record retention practices and applicable law.</Text>

                    <Text mt={6}>
                        <strong>Third Parties</strong>
                        <br />
                        Your Personal Information will not be sold, disclosed, or made available to any third party without your permission except as set forth in this Privacy Policy. We do not share your Personal Information with third parties for direct marketing purposes. Circumstances where we may divulge or transfer your Personal Information include our response to an order from a governmental authority, when you give us permission to do so, or when we otherwise are required by law to make the information available to a third party. We also reserve the right to report to law enforcement agencies any activities that we reasonably believe to be unlawful, and to release to such agencies Personal Information about users of the Site who we reasonably believe to be engaged in or involved with such unlawful activities.
                        We may share your Personal Information with our subsidiaries, affiliates and other companies who perform services on our behalf. Examples of such other companies include data analysis firms, customer service and support providers, email vendors, web hosting providers, cloud storage providers, and providers of corporate management services.
                        In the event that our company is (or all or substantially all of our assets are) sold to or otherwise acquired by a third party, merges with a third party, files for bankruptcy, ceases operations and dissolves, or undergoes a consolidation or reorganization pursuant to which the assets of our company have been or are to be transferred to another entity, you acknowledge and agree that Personal Information about you collected and maintained by us may be transferred to the acquiring third party.
                        We may de-identify your Personal Information and combine it with others' information (i.e., anonymous, aggregated data). This anonymous, aggregated data and other information that is not Personal Information may be used or shared without restriction.</Text>

                    <Text mt={6}><strong>Cookies and Do Not Track</strong><br />
                        Cookies are unique identifiers sent to your device by a web server and stored on your device's storage. A cookie allows many sites to identify you upon each visit with a personalized message before you
                        actively login or to track a returning user. Like many websites, we may use cookies, tracking pixels, web beacons, flash cookies and other technologies (collectively, "Tools") to improve your experience, or
                        collect information about your usage of the Site.
                        If you choose not to take advantage of these Tools, you can set up your browser to reject cookies or to alert you when a cookie has been sent. Most browsers offer instructions on how to reset the
                        browser to reject or delete cookies in the "Help" section of the toolbar, but please be aware that certain features or functions of the Site may not work properly if these Tools are disabled.
                        Please be aware that third party websites to which we provide links may use Tools when you visit them, and may use their Tools differently from us. Our Privacy Policy does not cover the use of Tools by
                        third party websites. We are unable to control the use of Tools by those websites, and thus recommend that you review their privacy policies before using them.
                        Do Not Track (DNT) is a privacy preference that users can set in their web browsers. When a user turns on DNT, the browser sends a message to websites requesting that they do not track the user.
                        Because standard Do Not Track technology is not yet finalized, and not all browsers support DNT, the Site's information collection and disclosure practices, and the choices that it provides to consumers,
                        may continue to operate as described in this Privacy Policy, whether or not a DNT signal is received. For more information about DNT see http://donottrack.us.
                    </Text>
                    <Text mt={6}><strong>Security</strong><br />
                        We use commercially reasonable administrative, physical, and technical measures that are reasonably designed to safeguard your personal information and help protect it from unauthorized access.
                        However, we cannot guarantee the security of your information since no security can be 100% effective. If you have reason to believe that your interaction with us is no longer secure, please immediately
                        notify us by contacting us as detailed in the How to Contact Us section, below.</Text>

                    <Text mt={6}><strong>Our Commitment to Children's Privacy</strong><br />
                        Protecting the privacy of children is important to us. For that reason, the Site is directed only to persons who are of legal age to enter a binding agreement and no part of the Site is structured to attract
                        anyone who is defined as a child under applicable law. If you believe that your child has submitted Personal Information to our company, please contact us as detailed in the How to Contact Us section,
                        below, so that we can take steps to delete the Personal Information he or she provided.
                    </Text>
                    <Text mt={6}><strong>Applicable Law</strong><br />
                        Information you provide to us will be collected, processed, stored, disclosed, and disposed of in accordance with applicable laws and our corporate policies. If you are a non-U.S. user, you acknowledge
                        and agree that we may collect and use your Personal Information and disclose it to other entities outside your resident jurisdiction, in the manner described above in the "Use of the Information We
                        Collect from Users" section of this Privacy Policy.
                        If you do not consent to the terms of this Privacy Policy, please do not use the Site. If you have already provided us with Personal Information and do not consent to the terms of this Privacy Policy, please
                        contact us as detailed in the How to Contact Us section, below, so that we can take steps to delete the Personal Information you provided.
                        EU Users: The United States has not received a finding of "adequacy" from the European Union under Article 65 of the GDPR. We rely on derogations for specific situations set forth in Article 69 of the
                        GDPR in order to transfer your personal information across international borders. In particular, we collect and transfer to the U.S. personal information only: (i) with your consent; (ii) to perform a
                        contract with you; or (iii) to fulfill our compelling legitimate interests in a manner that does not outweigh your rights and freedoms.</Text>

                    <Text mt={6}><strong>California Residents - Your California Privacy Rights</strong><br />
                        California Civil Code Section § 1798.83 permits users of the Site that are California residents to request certain information regarding our disclosure of personal information to third parties who may use
                        the information for direct marketing purposes. To make such a request, please contact us as detailed in the How to Contact Us section below.
                    </Text>

                    <Text mt={6}><strong>Other Rights</strong><br />
                        To the extent required by law, we provides users with (i) reasonable access to the personal information collected through our website, and (ii) the ability to review, correct, export, and delete such
                        personal information. Please contact us if you would like to exercise your individual rights.
                    </Text>
                    <Text mt={6}><strong>How to Contact Us</strong><br />
                        If you have any questions or comments regarding this Privacy Policy, please contact us by email addressed to our data protection officer at contact@digiblocks.tech. When contacting us,
                        please provide as much detail as possible in relation to your question, comment, or complaint. We take privacy concerns seriously and we will attempt to resolve any issue in a timely and efficient
                        manner.
                        EU Users: You have the right to lodge a complaint with a supervisory authority if you believe we have processed your information in a manner inconsistent with your privacy rights.

                        <Text mt={6}><strong>Other Terms and Conditions</strong><br />In addition to this Privacy Policy, your use of the Site is governed by the Site's Legal Notices and Terms of Use which are available on the Site. Please read them carefully. These Legal Notices and Terms of
                            Use are fully incorporated and made a part of this Privacy Policy by this reference, and they include terms governing choice of law, the venue for disputes, and other matters that are of importance to the
                            construction and application of this Privacy Policy.</Text>
                    </Text>
                </Box>
            </Container>
            <NewsLetter />
            <Footer />
        </ChakraProvider>
    );
};

export default PrivacyPolicy;

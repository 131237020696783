import React from "react";
import Hero from "../components/HomePageComponents/Hero";
import Servicecards from "../components/HomePageComponents/Services";
import Ratingcards from "../components/HomePageComponents/RatingCard";
import FeaturesBenefitsSection from "../components/HomePageComponents/FeaturesBenefites";
import FAQ from "../components/HomePageComponents/FAQ";
import NewContact from "../components/ContactComponents/Contact";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import Backtotop from "../components/Backtotop/Backtotop";
import Cookie from "../components/CookieComponent/Cookie";
import ContactFloating from "../components/ContactFLoating/ContactFloating";
import './HomePage.css';
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Helmet>
        <meta name="Digiblocks | Certify Develop Register" content="Digiblocks | Certify Develop Register" />
        <title>Digiblocks | Certify Develop Register</title>
      </Helmet>
      <ChakraProvider>
        <Header />
        <Cookie />
        <ContactFloating />
      </ChakraProvider>
      <Backtotop />
      <Hero />
      <Servicecards />
      <FeaturesBenefitsSection />
      <Ratingcards />
      <FAQ />
      <NewContact />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default HomePage;

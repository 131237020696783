import React, { useEffect } from "react";
import { Box, Flex, SimpleGrid, Text, Avatar, ChakraProvider } from "@chakra-ui/react";
import StarRatingDisplay from "./StarRatingDisplay";
import Typewriter from "typewriter-effect";
import AOS from "aos";
import "aos/dist/aos.css";

const Ratingcards = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  const rating = 4;
  const rating1 = 5;
  const themeColor = "#ef6042";

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const cardStyle = {
    border: `1px solid ${themeColor}`,
    borderRadius: "20px",
    padding: "20px",
    marginBottom: "30px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  };

  const reviews = [
    "Digiblocks made our registration process seamless. Their team is always available to offer support and answer any questions we have. Great service!",
    "I had an excellent experience with Digiblocks. They provided outstanding registration services, and their team was highly responsive and professional. Highly recommended!",
    "Digiblocks exceeded our expectations with their quality certification services. They helped us achieve the certifications we needed quickly and efficiently.",
    "I'm impressed with Digiblocks' development team. They created a website that perfectly suits our needs, and it's user-friendly for both our team and our customers.",
  ];

  return (
    <ChakraProvider>
      <Box w={"85%"} m={"auto"} mt={"50px"} mb={"50px"}>
        <Box w={"100%"}>
          <Text fontWeight={"700"} fontSize={["2xl", "3xl", "5xl"]}>
            <Typewriter
              options={{
                autoStart: true,
                autoload: true,
                delay: 150,
                strings: "What our clients say? ",
                cursor: " ",
              }}
            />
          </Text>
          <Text mb={"20px"} fontWeight={"400"} fontSize={"18px"}>
            Hear What Our Clients Have to Say About Us
          </Text>
        </Box>
        <SimpleGrid columns={[1, 2, 2]} gap={"50px"}>
          {[
            { rating: rating1, name: "Sunil Bafna" },
            { rating, name: "Pradeep Kumar" },
            { rating, name: "Rajesh Paul" },
            { rating: rating1, name: "Deepak Jain" },
          ].map((review, index) => (
            <Box
              key={index}
              data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
              style={cardStyle}
            >
              <StarRatingDisplay rating={review.rating} />
              <Text
                mt={"15px"}
                fontSize={"18px"}
                color={"gray.800"}
                maxWidth={["100%", "85%", "85%"]}
                overflowWrap="break-word"
              >
                {reviews[index]}
              </Text>
              <Flex mt={"15px"} gap={"20px"} alignItems={"center"}>
                <Avatar
                  onDragStart={handleDragStart}
                  name={review.name}
                  size={["sm", "sm", "md"]}
                  src="https://bit.ly/tioluwani-kolawole"
                />
                <Text fontWeight={"500"}>{review.name}</Text>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </ChakraProvider>
  );
};

export default Ratingcards;

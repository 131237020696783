import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import Header from '../components/HeaderComponents/Header'
import Footer from '../components/FooterComponents/Footer'
import TermsAndConditions from '../components/TermsAndCondititons/TermsAndConditions'
import NewsLetter from '../components/FooterComponents/Newsletter'
import { Helmet } from 'react-helmet'

const TermsAndConditionsPage = () => {
  return (
    <ChakraProvider>
      <Helmet>
        <meta name="Digiblocks | Terms & Conditions" content="Digiblocks | Terms & Conditions" />
        <title>Digiblocks | Terms & Conditions</title>
      </Helmet>
      <Header />
      <TermsAndConditions />
      <NewsLetter />
      <Footer />
    </ChakraProvider>
  )
}

export default TermsAndConditionsPage

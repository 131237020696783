import {
  FormControl,
  Input,
  Button,
  Heading,
  Container,
  Flex,
  Textarea,
  Text,
  ChakraProvider,
  useToast,
  Spinner, // Import Spinner component from Chakra UI
} from "@chakra-ui/react";
import React, { useState } from "react";
import axios from "axios";

const ContactBody = () => {
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission

  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Set to true when submitting

    try {
      const response = await axios.post("https://db-be-hheq.onrender.com/contacts", {
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
        message: messageValue,
      });
      


      if (response.status === 200) {
        toast({
          title: "Message Sent",
          description: "Your message has been sent successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setNameValue("");
        setEmailValue("");
        setPhoneValue("");
        setMessageValue("");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error",
        description: "An error occurred while sending your message. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false); // Set to false after submission is complete
    }
  };

  return (
    <ChakraProvider>
      <Container maxW="100%" p={["10px 20px", "20px 30px", "30px 50px"]} borderRadius="md">
        <Flex
          direction={["column", "column", "row"]}
          w={"90%"}
          margin={"auto"}
          flexWrap="wrap"
          flex={2}
        >
          <Flex direction={"column"} w={["100%", "100%", "50%"]} m={"40px auto"}>
            <Flex direction="column" gap={4}>
              <Heading fontWeight={700} fontSize={["3xl", "4xl", "5xl"]}>
                Have Questions?
              </Heading>
              <Text color="gray.600" fontSize={"lg"}>
                Please feel free to call or email us,
                <br /> or use our contact form to get in touch with us.
                <br /> We look forward to hearing from you!
              </Text>
              <Text fontSize={"xl"} fontWeight="600" color="red.500">
                Call Us:
              </Text>
              <Text color="gray.600" fontSize={"lg"}>
                +91-8953641329
              </Text>
              <Text fontSize={"xl"} fontWeight="600" color="red.500">
                Send Us Mail
              </Text>
              <Text color="gray.600" fontSize={"lg"}>
                admin@digiblocks.tech
              </Text>
              <Text fontSize={"xl"} fontWeight="600" color="red.500">
                Find the Office
              </Text>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0579634830806!2d77.57909047559579!3d12.968142814953024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae174e0e0083c7%3A0xb13cf16d5235f897!2sDigiblocks%20Private%20Limited!5e0!3m2!1sen!2sin!4v1698324051048!5m2!1sen!2sin"
                width="90%"
                height="250"
                alt="img"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Flex>
          </Flex>

          <Flex
            direction={"column"}
            m={"auto"}
            w={["100%", "100%", "50%"]}
            mt={["30px", "30px", "0"]}
            p={["10px", "20px", "30px"]}
          >
            <FormControl w="100%" m={"auto"} textAlign="center" mt={["20px", "30px", "50px"]}>
              <Flex
                direction="column"
                alignItems="center"
                gap={["20px", "30px", "40px"]}
                bg={"#fef6f6"}
                p={["10px", "20px", "40px"]}
                pt={"60px"}
                pb={"60px"}
                borderRadius="30px"
                boxShadow="lg"
              >
                <Input
                  bg={"white"}
                  placeholder="Name"
                  width="80%"
                  p={3}
                  mb={3}
                  border="1px solid gray.400"
                  borderRadius="md"
                  _focus={{ borderColor: "red.500" }}
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                />
                <Input
                  bg={"white"}
                  placeholder="Email"
                  width="80%"
                  p={3}
                  mb={3}
                  border="1px solid gray.400"
                  borderRadius="md"
                  _focus={{ borderColor: "red.500" }}
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                />
                <Input
                  bg={"white"}
                  placeholder="Phone"
                  width="80%"
                  p={3}
                  mb={3}
                  border="1px solid gray.400"
                  borderRadius="md"
                  _focus={{ borderColor: "red.500" }}
                  value={phoneValue}
                  onChange={(e) => setPhoneValue(e.target.value)}
                />
                <Textarea
                  bg={"white"}
                  placeholder="Message"
                  width="80%"
                  p={3}
                  mb={4}
                  border="1px solid gray.400"
                  borderRadius="md"
                  _focus={{ borderColor: "red.500" }}
                  value={messageValue}
                  onChange={(e) => setMessageValue(e.target.value)}
                />
                <Button
                  type="submit"
                  backgroundColor={"#ef6042"}
                  _hover={{ bg: "#d94c32" }}
                  color="white"
                  width="80%"
                  p={4}
                  borderRadius="md"
                  onClick={handleSubmit}
                >
                  {isSubmitting ? <Spinner size="sm" color="white" /> : "Send Message"}
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        </Flex>
      </Container>
    </ChakraProvider>
  );
};

export default ContactBody;

import React from 'react'
import Header from '../components/HeaderComponents/Header'
import SingleRegistration from '../components/SingleRegistration/SingleRegistration'
import { ChakraProvider } from '@chakra-ui/react'
import NewsLetter from '../components/FooterComponents/Newsletter'
import Footer from '../components/FooterComponents/Footer'
import ContactFloating from '../components/ContactFLoating/ContactFloating'
import { Helmet } from 'react-helmet'

const SingleRegistrationPage = () => {
  return (
    <>
      <Helmet>
        <meta name="Digiblocks | Apply for Registration" content="Digiblocks | Apply for Registration" />
        <title>Digiblocks | Apply for Registration</title>
      </Helmet>
      <ChakraProvider>
        <Header />
        <ContactFloating />
        <SingleRegistration />
      </ChakraProvider>
      <NewsLetter />
      <Footer />
    </>
  )
}

export default SingleRegistrationPage

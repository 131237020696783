import React from 'react';
import { Helmet } from 'react-helmet';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <meta name="Digiblocks | 404" content="Digiblocks | 404" />
        <title>Digiblocks | 404</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center min-h-screen bg-[#fef6f6] text-black">
        <div className="text-center">
          <p className="text-6xl font-bold mb-4">404</p>
          <h1 className="text-4xl font-bold tracking-tight mb-2">Oops! Page not found</h1>
          <p className="text-lg mb-8">Sorry, we couldn't find the page you're looking for.</p>
          <div className="space-x-4">
            <a
              href="/"
              className="bg-[#ef6042] hover:bg-red-700 text-white font-semibold py-2 px-6 rounded-full transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Go back home
            </a>
            <a
              href="/contact"
              className="text-[#ef6042] hover:underline text-lg font-semibold"
            >
              Contact support <span className="ml-2">&#8594;</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

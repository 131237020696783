import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const Cookie = () => {
let [accept,setAccept]=useState(false);

useEffect(() => {
    const hasAccepted = document.cookie.split(';').some((item) => item.trim().startsWith('cookieConsentAccepted='));
    if (hasAccepted) {
      setAccept(true);
    }
  }, []);

  const handleAccept = () => {
    document.cookie = 'cookieConsentAccepted=true; max-age=2592000'; // Expires in 30 days
    setAccept(true);
  };
return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      padding="1rem"
      background="gray.700"
      color="white"
      textAlign="center"
      borderTop="1px solid"
      borderTopColor="gray.600"
      zIndex={"1000"}
      display={accept?"none":"block"}
    >
      <Text p={["","","0 100px"]} marginBottom="0.5rem" fontSize={["12px","md","20px"]}>
        This website uses cookies to improve your experience. We'll assume you're okay with this, but you can opt-out if you wish.
      Also know more about our terms and coditions.
      </Text>
      <Flex justifyContent="center" gap={"20px"}>
        <Button
          colorScheme="orange"
          variant={"outline"}
          // _hover={{ backgroundColor: 'green.500' }}
            as={Link}
            to={"/terms"}
        >
          Read More
        </Button>
        <Button
          colorScheme="orange"
          _hover={{ backgroundColor: 'red' }}
          onClick={handleAccept}
        >
          Accept
        </Button>
      </Flex>
    </Box>
  );
};

export default Cookie;

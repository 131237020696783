import React from 'react';
import {
    Box,
    Container,
    Text,
    Heading,
    VStack,
    ChakraProvider,
} from '@chakra-ui/react';
import Header from '../components/HeaderComponents/Header';
import NewsLetter from '../components/FooterComponents/Newsletter';
import Footer from '../components/FooterComponents/Footer';
import { Helmet } from 'react-helmet';

const ShippingAndDelivery = () => {
    return (
        <ChakraProvider>
            <Helmet>
                <meta name="Digiblocks | Shipping & Delivery Policy" content="Digiblocks | Shipping & Delivery Policy" />
                <title>Digiblocks | Shipping & Delivery Policy</title>
            </Helmet>
            <Header />
            <Container maxW="6xl" py={10} pt={"9%"} mb={"4%"}>
                <Box p={6} borderRadius="lg" boxShadow="lg" bg="#fef6f6">
                    <Heading as="h1" color={"#ef6042"} size="xl" mb={4}>
                        Shipping & Delivery
                    </Heading>
                    <Text>
                        At Digiblocks, we aim to provide you with a seamless and convenient experience from the moment you place an order to the delivery of your services. We understand the importance of timely delivery and quality assurance. Below, you'll find information about our shipping and delivery processes.
                    </Text>

                    <VStack align="start" mt={6} spacing={4}>
                        <Heading as="h2" size="lg">
                            Our Services
                        </Heading>
                        <Text>
                            Digiblocks is your trusted digital agency, specializing in three core services: Quality Certificates, Development, and Registration Services. Our user-friendly website is designed to make it easy for you to place orders and make inquiries about our services.
                        </Text>

                        <Heading as="h3" size="md">
                            Quality Certificates
                        </Heading>
                        <Text>
                            For services related to quality certificates, we ensure a swift and hassle-free process. Once your order is confirmed and approved, you will receive the digital certificate by email or a secure download link. This allows you to access and utilize your quality certificate instantly.
                        </Text>

                        <Heading as="h3" size="md">
                            Development
                        </Heading>
                        <Text>
                            Our development services are aimed at enhancing your digital presence and capabilities. Similar to quality certificates, upon approval, you'll receive digital deliverables, such as code, designs, or other digital assets, via email or a secure download link.
                        </Text>

                        <Heading as="h3" size="md">
                            Registration Services
                        </Heading>
                        <Text>
                            When it comes to registration services, we understand the importance of official documentation. After the necessary processing and approvals, your physical certificates and documents will be dispatched to you via courier. We take utmost care to ensure that your documents are safely delivered to your provided address.
                        </Text>

                        <Heading as="h2" size="lg">
                            Timely and Secure Delivery
                        </Heading>
                        <Text>
                            We prioritize delivering our services in a timely and secure manner. Whether you receive your digital goods instantly through email or a download link, or physical certificates by courier, our commitment to quality and efficiency remains unwavering.
                        </Text>

                        <Text>
                            Should you have any specific questions or concerns about shipping and delivery, please don't hesitate to <a href="/contact" style={{ color: '#ef6042' }}>contact our customer support</a> team. We're here to assist you throughout the process.
                        </Text>

                        <Text>
                            At Digiblocks, we're dedicated to providing you with a reliable and user-friendly experience, ensuring your satisfaction with every order.
                        </Text>

                        <Text>
                            Thank you for choosing Digiblocks for your digital needs!
                        </Text>
                    </VStack>
                </Box>
            </Container>
            <NewsLetter />
            <Footer />
        </ChakraProvider>
    );
};

export default ShippingAndDelivery;

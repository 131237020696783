import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./Routes/AllRoutes";
import { ChakraProvider } from "@chakra-ui/react";



function App() {
  return (
    <Router>
     <ChakraProvider><AllRoutes />
     </ChakraProvider>
      
    </Router>
  );
}

export default App;
import React, { createContext, useState } from 'react';

const MyContext = createContext();

const MyContextProvider = ({ children }) => {

  const [myData, setMyData] = useState({});
  const [formData, setFormData] = useState({
    contactInfo: {
        name: '',
        email: '',
        phone: '',
    },
    certificationInfo: {
        certification: '',
        panCard: '',
        gstin: '',
    },
    additionalInfo: {
        comments: '',
    },
    paymentInfo:{
        amount:0,
        Method:"card" ,
    },
    status:"pending"
});

  return (
    <MyContext.Provider value={{ myData,setMyData,formData,setFormData }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyContextProvider, MyContext };

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your components here
import HomePage from '../Pages/HomePage';
import AboutPage from '../Pages/AboutPage';
import CertificationPage from '../Pages/CertificationPage';
import SingleCertificatePage from '../Pages/SingleCertificatePage';
import DevelopmentPage from '../Pages/DevelopmentPage';
import SingleRegistrationPage from '../Pages/SingleRegistrationPage';
import RegistrationPage from '../Pages/RegistrationPage';
import UploadDocuments from '../components/SingleRegistration/UploadDocuments';
import ContactPage from '../Pages/ContactPage';
import RefundPolicy from '../Pages/Refund&CancellationPage';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import TermsAndConditionsPage from '../Pages/TermsAndConditionsPage';
import ShippingAndDelivery from '../Pages/ShippingAndDelivery';
import AdminLogin from '../Pages/AdminLogin';
import Sidebar from '../components/Admin/Sidebar';
import NotFound from '../Pages/NotFound';

function AllRoutes() {
  return (
    <Routes>
      {/* Use a Suspense component with a centered BeatLoader spinner */}
      <Route exact path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/certificates" element={<CertificationPage />} />
      <Route path="/single-certificate" element={<SingleCertificatePage />} />
      <Route path="/development" element={<DevelopmentPage />} />
      <Route path="/registration" element={<SingleRegistrationPage />} />
      <Route path="/single-registration" element={<RegistrationPage />} />
      <Route path="/upload-docs" element={<UploadDocuments />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsAndConditionsPage />} />
      <Route path="/shipping-delivery" element={<ShippingAndDelivery />} />
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/admin" element={<Sidebar />} />
      {/* ERROR PAGES */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AllRoutes;

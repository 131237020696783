import { Box, Heading, Text, VStack, Container, Divider, ChakraProvider, Button } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import { Helmet } from "react-helmet";

const theme = extendTheme({
    colors: {
        brand: {
            primary: "#ef6042",
        },
    },
});


const RefundPolicy = () => {
    return (
        <>

            <Helmet>
                <meta name="Digiblocks | Refund & Cancellation Policy" content="Digiblocks | Refund & Cancellation Policy" />
                <title>Digiblocks | Refund & Cancellation Policy</title>
            </Helmet>
            <ChakraProvider theme={theme}>
                <Header />
                <Container maxW="80%" margin={"auto"} pt={"10%"} mb={"5%"} centerContent>
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        p={6}
                        boxShadow="lg"
                        bg="#fef6f6"
                        textAlign="left"
                    >
                        <Heading as="h2" size="xl" mb={4} color="brand.primary">
                            Refund and Cancellation Policy
                        </Heading>
                        <Text>
                            We thank you and appreciate your service or product purchase with us on our website. Please read the refund policy carefully as they will give you important information and guidelines about your rights and obligations as our customer, with respect to any purchase or service we provide to you.
                        </Text>
                        <Text mt={4}>
                            We make every effort to provide the service to you as per the specifications and timelines mentioned against each service or product purchased by you from Digiblocks, however, if, due to any reason, we are unable to provide to you the service or product you purchased from us, then you shall be entitled to a refund which shall be subject to the following situations:
                        </Text>
                        <VStack align="start" mt={4} spacing={2}>
                            <Text fontWeight="bold">1. The Refund shall be only considered in the event there is a clear, visible deficiency in delivery of the service or product by Digiblocks or its designated representatives.</Text>
                            <Text fontWeight="bold">2. In the event a customer has paid for a service and then requests for a refund only because there was a change in mind, the refund shall not be considered.</Text>
                            <Text fontWeight="bold">3. If a customer withdraws his application due to external factors (rejection from Registrar, Trademark office, or any other), the customer will not be entitled to a refund.</Text>
                            <Text fontWeight="bold">4. If an application is pending with the concerned Govt. department for an elongated period of time, a refund will not be processed.</Text>
                            <Text fontWeight="bold">5. If the request for a refund has been raised 30 days after the purchase of a service/product or service/product delivery has been completed and the same has been intimated and indicated via email or through any form of communication stating that the work has been completed, then, in such cases a refund will not be considered.</Text>
                            <Text fontWeight="bold">6. In case the client has not been responsive for over two months, the order will be considered null and void and will not be considered for a refund.</Text>
                        </VStack>
                        <Divider mt={6} />
                        <Text mt={6}>
                            If the request for the refund has been approved by Digiblocks, the same shall be processed and intimated to you via email. This refund process could take a minimum of 15 (fifteen) business days to process and shall be credited to your bank account accordingly. We shall handle the refund process with care and ensure that the money spent by you is returned to you at the earliest.
                        </Text>
                    </Box>
                </Container>
            </ChakraProvider>
            <NewsLetter />
            <Footer />
        </>
    );
};

export default RefundPolicy;

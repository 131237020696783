import React from "react";
import Contactbody from "../components/ContactComponents/ContactBody";
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import Backtotop from "../components/Backtotop/Backtotop";

import ContactFloating from "../components/ContactFLoating/ContactFloating";
import { Helmet } from "react-helmet";


const ContactPage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Helmet>
        <meta name="Digiblocks | Contact Us" content="Digiblocks | Contact Us" />
        <title>Digiblocks | Contact Us</title>
      </Helmet>
      <ChakraProvider>
        <Header />
        <Backtotop />
        <ContactFloating />
      </ChakraProvider>

      <div style={{ marginBottom: "5%", marginTop: "5%" }}>
        <Contactbody />
      </div>
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default ContactPage;

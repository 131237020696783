import React, { useEffect, useState } from 'react';
import { Box, Text, Center,Flex, CircularProgress } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { ArcElement, Chart } from 'chart.js'; // Import ArcElement and Chart

Chart.register(ArcElement); // Register ArcElement

const ContactChart = () => {
  const [chartData, setChartData] = useState(null);
  const [pC, setPc] = useState(0);
  const [rC, setRc] = useState(0);

  useEffect(() => {
    axios
      .get('https://db-be-hheq.onrender.com/contacts') // Replace with your API endpoint
      .then((response) => {
        if (Array.isArray(response.data)) {
          const resolvedCount = response.data.filter((contact) => contact.status === true).length;
          const pendingCount = response.data.filter((contact) => contact.status === false).length;


          setRc(resolvedCount);
          setPc(pendingCount);
          const data = {
            labels: ['Resolved', 'Pending'],
            datasets: [
              {
                data: [resolvedCount, pendingCount],
                backgroundColor: ['green', 'orange'],
                borderColor: ['green', 'orange'],
                borderWidth: 1,
              },
            ],
          };

          setChartData(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <Box
      p={4}
      borderRadius="30px"
      textAlign="left"
      boxShadow="lg"
      bg={"white"}
      padding={"2%"}
      width={"50%"}
    >
      <Text fontSize="2xl" mb={4} fontWeight={"bolder"}>
        Contact Queries : {rC+pC}
      </Text>
      <Flex justifyContent="space-between">
        <Text fontSize="xl" color="orange" fontWeight="bolder">Pending Requests: {pC}</Text>
        <Text fontSize="xl" color="green" fontWeight="bolder">Resolved Requests: {rC}</Text>

      </Flex>
      {chartData ? (
        <Center>
          <Doughnut data={chartData} />
        </Center>
      ) : (
        <Center>
          <CircularProgress isIndeterminate color="teal" size="60px" />
        </Center>
      )}
    </Box>
  );
};

export default ContactChart;

import React, { useEffect, useState } from 'react';
import { Box, Text, Center, CircularProgress, Flex } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { ArcElement, Chart } from 'chart.js';

Chart.register(ArcElement);

const CertificationChart = () => {
    const [chartData, setChartData] = useState(null);
    const [pC, setPc] = useState(0);
    const [rC, setRc] = useState(0);
    const [aC, setAc] = useState(0);

    useEffect(() => {
        axios
            .get('https://db-be-hheq.onrender.com/certifications')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    const certificationData = response.data;

                    const pendingCount = certificationData.filter((certification) => certification.status === 'pending').length;
                    const reviewCount = certificationData.filter((certification) => certification.status === 'review').length;
                    const approvedCount = certificationData.filter((certification) => certification.status === 'approved').length;

                    setAc(approvedCount);
                    setPc(pendingCount);
                    setRc(reviewCount);

                    const data = {
                        labels: ['Pending', 'Review', 'Approved'],
                        datasets: [
                            {
                                data: [pendingCount, reviewCount, approvedCount],
                                backgroundColor: ['orange', '#FFDF00', 'green'], // Changed 'yellow' to '#FFDF00' for Review
                                borderColor: ['orange', '#FFDF00', 'green'],
                                borderWidth: 1,
                            },
                        ],
                    };

                    const options = {
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                        },
                        tooltips: {
                            callbacks: {
                                label: function (context) {
                                    const labelIndex = context.dataIndex;
                                    return data.labels[labelIndex] + ': ' + context.parsed + ' counts';
                                },
                            },
                        },
                    };

                    setChartData({ data, options });
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <Box
            p={4}
            borderRadius="30px"
            textAlign="left"
            boxShadow="lg"
            bg="white"
            padding="2%"
            width="50%"
        >
            <Text fontSize="2xl" mb={4} fontWeight="bolder">
                Certification Requests : {rC+aC+pC}
            </Text>
            <Flex justifyContent="space-between">
                <Text fontSize="xl" color="orange" fontWeight="bolder">Pending Requests: {pC}</Text>
                <Text fontSize="xl" color="#FFDF00" fontWeight="bolder">Review Requests: {rC}</Text>
                <Text fontSize="xl" color="green" fontWeight="bolder">Approved Requests: {aC}</Text>
            </Flex>
            {chartData && chartData.data ? (
                <Center>
                    <Doughnut data={chartData.data} options={chartData.options} />
                </Center>
            ) : (
                <Center>
                    <CircularProgress isIndeterminate color="teal" size="60px" />
                </Center>
            )}
        </Box>
    );
};

export default CertificationChart;

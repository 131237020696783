import {
  Box,
  Flex,
  Image,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import React from "react";
import { NavLink, Link as RouterLink } from "react-router-dom";
import logo from "../../assets/Digiblocks (2).png";


const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let handledrag = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Flex
        justifyContent={["space-between", "space-between", "space-between"]}
        alignItems="center"
        zIndex={1000}
        w={["100%", "100%", "100%"]}
        m={"auto"}
        position="fixed"
        bg="white"
        p={["0px", "10px 20px", "10px 30px"]}
        overflow={"hidden"}
        boxShadow={"xl"}
      >
        <Flex
          as={RouterLink}
          to="/"
          display={"flex"}
          marginLeft={"10%"}
          justifyContent={"space-between"}
        >
          <Image
            onDragStart={handledrag}
            w={["40%", "40%", "50%"]}
            src={logo}
            alt="Logo"
          />
        </Flex>
        <Box pr={["20px", "50px"]}>
          <IconButton
            display={["block", "block", "none"]}
            onClick={isOpen ? onClose : onOpen}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Toggle navigation"
            color="#ef6042"
            background={"none"}
            size={"lg"}
          />
        </Box>
        <Flex
          display={["none", "none", "flex"]}
          gap={"30px"}
          alignItems="center"
          justifyContent="center"
          flexDirection={["column", "column", "row"]}
          flexBasis={["100%", "100%", "60%"]}
          fontWeight={"semibold"}
        >
          <Box
            as={RouterLink}
            to="/"
            sx={{
              textDecoration: "none",
              "&:hover::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-2px",
                height: "4px",
                backgroundColor: "#ef6042",
                transition: "width 0.4s",
              },
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
          >
            Home
          </Box>

          <Box
            as={RouterLink}
            sx={{
              textDecoration: "none",
              "&:hover::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-2px",
                height: "4px",
                backgroundColor: "#ef6042",
                transition: "width 0.4s",
              },
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
            to="/certificates"
          >
            Certifications
          </Box>

          <Box
            as={RouterLink}
            sx={{
              textDecoration: "none",
              "&:hover::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-2px",
                height: "4px",
                backgroundColor: "#ef6042",
                transition: "width 0.4s",
              },
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
            to="/development"
          >
            Development
          </Box>
          <Box
            as={RouterLink}
            sx={{
              textDecoration: "none",
              "&:hover::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-2px",
                height: "4px",
                backgroundColor: "#ef6042",
                transition: "width 0.4s",
              },
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
            to="/single-registration"
          >
            Registration
          </Box>
          <Box
            as={RouterLink}
            sx={{
              textDecoration: "none",
              "&:hover::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-2px",
                height: "4px",
                backgroundColor: "#ef6042",
                transition: "width 0.4s",
              },
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
            to="/about"
          >
            About Us
          </Box>
          <Box
            as={RouterLink}
            sx={{
              textDecoration: "none",
              "&:hover::after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: "-2px",
                height: "4px",
                backgroundColor: "#ef6042",
                transition: "width 0.4s",
              },
              "&:hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
            to="/contact"
          >
            Contact Us
          </Box>
        </Flex>
      </Flex>

      {isOpen && (
        <Flex
          flexDirection="column"
          bg="white"
          p="2"
          fontWeight={"semibold"}
          alignItems="center"
          position="fixed"
          top="12%"
          w="100%"
          zIndex="999"
        >
          <NavItem to="/" onClose={onClose}>
            Home
          </NavItem>
          <NavItem to="/certificates" onClose={onClose}>
            Certifications
          </NavItem>
          <NavItem to="/development" onClose={onClose}>
            Development
          </NavItem>
          <NavItem to="/single-registration" onClose={onClose}>
            Registration
          </NavItem>
          <NavItem to="/about" onClose={onClose}>
            About Us
          </NavItem>
          <NavItem to="/contact" onClose={onClose}>
            Contact Us
          </NavItem>
        </Flex>
      )}
    </>
  );
};

const NavItem = ({ to, children, onClose }) => {
  return (
    <Box
      as={RouterLink}
      to={to}
      p="2"
      sx={{
        position: "relative",
        display: "inline-block",
        "&:hover::after": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          bottom: "-2px",
          height: "4px",
          backgroundColor: "#ef6042",
          transition: "width 0.4s",
        },
        "&:hover": {
          transform: "scale(1.1)",
          cursor: "pointer",
        },
      }}
    >
      <></>
      {children}
    </Box>
  );
};

export default Header;




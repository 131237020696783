import {
    Box,
    ChakraProvider,
    Container,
    SimpleGrid,
    Stack,
    Text,
    Link,
} from '@chakra-ui/react';
import logo from '../../assets/Digiblocks (2).png';
import { Link as RouterLink } from 'react-router-dom';

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

export default function Footer() {
    return (
        <ChakraProvider>
            <Box
                bg={"white"}>
                <Container as={Stack} maxW={'6xl'} py={10}>
                    <SimpleGrid
                        templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
                        spacing={8}>
                        <Stack spacing={1} style={{ alignItems: "center" }}>
                            <Box>
                                <img src={logo} style={{ width: '100%' }} alt="img" />
                            </Box>
                            <Text fontSize={'sm'}>© 2023 Digiblocks. All rights reserved</Text>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Our Services</ListHeader>
                            <RouterLink to="/certificates" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                                <Text _hover={{ color: '#ef6042'}}>Certifications</Text>
                            </RouterLink>
                            <RouterLink to="/development" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Development</Text>
                            </RouterLink>
                            <RouterLink to="/single-registration" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Registrations </Text>
                            </RouterLink>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Organization</ListHeader>
                            <RouterLink to="/about" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>About Us</Text>
                            </RouterLink>
                            <RouterLink to="/contact" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Contact Us</Text>
                            </RouterLink>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Support</ListHeader>
                            <RouterLink to="/contact" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Help Center</Text>
                            </RouterLink>
                            <RouterLink to="/terms" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Terms & Conditions </Text>
                            </RouterLink>
                            <RouterLink to="/refund-policy" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Refund Policy</Text>
                            </RouterLink>
                            <RouterLink to="/privacy-policy" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Privacy Policy</Text>
                            </RouterLink>
                            <RouterLink to="/shipping-delivery" as={Link} _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Shipping & Delivery</Text>
                            </RouterLink>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Follow Us</ListHeader>
                            <Link href={'https://www.linkedin.com/company/digiblocks-llc/mycompany/'} target="_blank" _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>LinkedIn</Text>
                            </Link>
                            <Link href={'https://twitter.com/DigiBlocks_LLC'} target="_blank" _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Twitter</Text>
                            </Link>
                            <Link href={'https://www.instagram.com/digiblocks_llc/'} target="_blank" _hover={{ color: '#ef6042', textDecoration: 'none' }}>
                            <Text _hover={{ color: '#ef6042'}}>Instagram</Text>
                            </Link>
                        </Stack>
                    </SimpleGrid>
                </Container>
            </Box>
        </ChakraProvider>
    )
}

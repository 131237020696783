import React, { useEffect, useState } from "react";
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import Backtotop from "../components/Backtotop/Backtotop";
import ContactFloating from "../components/ContactFLoating/ContactFloating";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from '../assets/Digiblocks_page-0001.jpg'; // Import your images
import img2 from '../assets/Digiblocks_page-0002.jpg';
import img3 from '../assets/Digiblocks_page-0003.jpg';
import img4 from '../assets/Digiblocks_page-0004.jpg';
import img5 from '../assets/Digiblocks_page-0005.jpg';
import img6 from '../assets/Digiblocks_page-0006.jpg';

const AboutPage = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init();
  }, []);

  const themeColor = "#ef6042"; // Your theme color

  const imageUrls = [img1, img2, img3,img4,img5,img6];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % imageUrls.length
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="Digiblocks | About Us" content="Digiblocks | About Us" />
        <title>Digiblocks | About Us</title>
      </Helmet>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <Backtotop />
        <ChakraProvider>
          <Header />
          <ContactFloating />
        </ChakraProvider>

        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 mt-20 px-4">
          <div
            className="flex flex-col lg:flex-row justify-between gap-8"
            data-aos="fade-up"
          >
            <div className="w-full lg:w-5/12 flex flex-col justify-center">
              <h1
                className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4"
                style={{ color: themeColor }}
              >
                About Us
              </h1>
              <p className="font-normal text-lg leading-6 text-gray-600">
                Digiblocks is your trusted partner for digital solutions. Our
                mission is to empower businesses across India with top-notch quality
                certifications, development services, and seamless registration
                assistance. We are committed to simplifying your journey towards
                success.
              </p>
            </div>
            <div className="w-full lg:w-8/12 rounded-md">
              {/* Use the <Carousel> component to display the images */}
              <Carousel
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                selectedItem={currentImageIndex}
              >
                {imageUrls.map((url, index) => (
                  <div key={index}>
                    <img src={url} alt={`Image ${index}`} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>

        <NewsLetter />
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;

import { Box, ChakraProvider, Flex, Heading, Button, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";

const SingleRegistration = () => {
  const buttonWithAnimation = {
    animation: "applyButton 2s infinite, blink 1s infinite",
  };

  return (
    <ChakraProvider>
      <Box p={"30px"} pl={"8%"} pr={"8%"}>
        <style>
          {`
            @keyframes applyButton {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
              100% {
                transform: scale(1);
              }
            }

            @keyframes blink {
              0% {
                opacity: 0;
              }
              50% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }
          `}
        </style>
        <Flex pt={"20px"} direction={"column"} marginTop={"100px"}>
          <Heading fontSize={["28px", "35px", "40px"]} fontWeight={"bold"}>
            What is GST registration?
          </Heading>
          <Text p={"10px"}>
            Businesses above the threshold limit of Lakhs are required to
            register under GST mandatorily. The turnover is 10 Lakhs for
            businesses that are present in hill states and North-Eastern states.
            The GST registration process is completed within 6 working days.
            After completing the GST registration process, the Central
            Government provides a <b>Goods and Service Tax Identification Number
              (GSTIN)</b>. The unique 15-digit GSTIN is used to determine whether a
            business is liable to pay GST.
          </Text>
        </Flex>

        <Flex pt={"20px"} direction={"column"} justifyContent={"center"} mt={"3%"}>
          <Heading fontSize={["28px", "35px", "40px"]} fontWeight={"bold"}>
            Eligibility for GST Registration / GST Threshold Limit
          </Heading>
          <Flex alignItems={"center"} direction={["column", "column", "row"]} justifyContent={"center"} gap={"30px"} p={"20px"}>
            <Text w={["100%", "100%", "50%"]} p={"20px"} fontSize={["15px", "16px", "18px"]}>
              <ul>
                <li style={{ padding: "5px" }}> • Individuals registered under the Pre-GST law (i.e., Excise, VAT, Service Tax, etc.)</li>
                <li style={{ padding: "5px" }}> • Businesses with a turnover above the threshold limit of 10 Lakhs (1 crore for North-Eastern States, J&K, Himachal Pradesh, and Uttarakhand)</li>
                <li style={{ padding: "5px" }}> • Casual taxable persons / Non-Resident taxable persons</li>
                <li style={{ padding: "5px" }}> • Agents of a supplier & Input service distributor</li>
                <li style={{ padding: "5px" }}> • Those paying tax under the reverse charge mechanism</li>
                <li style={{ padding: "5px" }}> • A person who supplies via an e-commerce aggregator</li>
                <li style={{ padding: "5px" }}> • Every e-commerce aggregator</li>
                <li style={{ padding: "5px" }}> • Persons supplying online information and database access or retrieval services from a place outside India to a person in India, other than a registered taxable person</li>
              </ul>
            </Text>
            <Image w={["100%", "100%", "45%"]} src="https://img.freepik.com/free-vector/tax-form-concept-illustration_114360-7378.jpg?w=2000&t=st=1697077586~exp=1697078186~hmac=bda588e0df383a3c140019cb2d28fc5cab6889e1a4586698f30c9b83ca43781f" alt="img" />
          </Flex>
        </Flex>

        <Flex pt={"20px"} direction={"column"} justifyContent={"center"}>
          <Heading fontSize={["28px", "35px", "40px"]} fontWeight={"bold"}>GST Registration Process</Heading>
          <Flex direction={["column", "column", "row"]} justifyContent={"center"} gap={"30px"} p={"20px"}>
            <Image w={["90%", "90%", "45%"]} src="https://img.freepik.com/free-vector/tax-preparation-concept-illustration_114360-19336.jpg?w=1380&t=st=1697077515~exp=1697078115~hmac=55bd21064ab4e707b5ec3ee23cdf053f58b056645c21651f23a707bba43ecee8"  alt="img"
            />
            <Text m={"auto"} w={["100%", "100%", "50%"]} p={"20px"} fontSize={["15px", "16px", "18px"]}>
              <ul>
                <li style={{ padding: "5px" }}> • TRN Generation</li>
                <li style={{ padding: "5px" }}> • Documents Uploading</li>
                <li style={{ padding: "5px" }}> • Show Cause Notice (if deficiency)</li>
                <li style={{ padding: "5px" }}>• Reply to Notice (if deficiency)</li>
                <li style={{ padding: "5px" }}>• Issuance of GST certificate (GSTIN)</li>
              </ul>
            </Text>
          </Flex>
        </Flex>

        <Flex pt={"20px"} direction={"column"} justifyContent={"center"}>
          <Heading fontSize={["28px", "35px", "40px"]} fontWeight={"bold"}>Documents Required for Registration</Heading>
          <Flex alignItems={"center"} direction={["column", "column", "row"]} justifyContent={"center"} gap={"30px"} p={"20px"}>
            <Text w={["100%", "100%", "50%"]} p={"20px"} fontSize={["15px", "16px", "18px"]}>
              <ul>
                <li style={{ padding: "5px" }}> • Copy of PAN Card of the Applicant</li>
                <li style={{ padding: "5px" }}> • Copy of Aadhaar Card</li>
                <li style={{ padding: "5px" }}> • Incorporation Certificate or Business Registration Proof</li>
                <li style={{ padding: "5px" }}>• Address Proof (Rent Agreement / Electricity Bill)</li>
                <li style={{ padding: "5px" }}>• Canceled Cheque / Bank Statement</li>
                <li style={{ padding: "5px" }}>• Digital Signature</li>
                <li style={{ padding: "5px" }}>• Passport size photograph</li>
                <li style={{ padding: "5px" }}>• Letter of Authorization / Board Resolution for Authorized Signatory</li>
              </ul>
            </Text>
            <Image w={["100%", "100%", "45%"]} src="https://img.freepik.com/free-vector/text-files-concept-illustration_114360-4402.jpg?w=2000&t=st=1697077636~exp=1697078236~hmac=5472919ec3c97b91362f72a27266fe6d29563bb0f777f0135d815ee28aa3f71c" alt="img" />
          </Flex>
        </Flex>
        <Flex
          position="fixed"
          bottom="50%"
          right="50px"
          alignItems="center"
          justifyContent="center"
          style={buttonWithAnimation} // Apply the animations
        >
          <Link to="/upload-docs">
            <Button variant="solid" colorScheme="red" size="lg">
              Apply Now
            </Button>
          </Link>
        </Flex>

      </Box>
    </ChakraProvider>
  );
};

export default SingleRegistration;

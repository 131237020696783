import React, { useState, useEffect } from "react";
import {
    Container,
    Stack,
    Box,
    Heading,
    Text,
    Button,
    ChakraProvider,
    Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import img1 from '../../assets/Digiblocks_page-0001.jpg';
import img2 from '../../assets/Digiblocks_page-0002.jpg';
import img3 from '../../assets/Digiblocks_page-0003.jpg';
import img4 from '../../assets/Digiblocks_page-0004.jpg';
import img5 from '../../assets/Digiblocks_page-0005.jpg';
import img6 from '../../assets/Digiblocks_page-0006.jpg';

// Define an array of image URLs for your carousel
const imageUrls = [img1, img2, img3, img4, img5, img6];

export default function Top() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const interval = setTimeout(() => {
            if (!isHovered) {
                setCurrentImageIndex((prevIndex) =>
                    (prevIndex + 1) % imageUrls.length
                );
            }
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, [currentImageIndex, isHovered]);

    return (
        <ChakraProvider>
            <Container
                maxW={["100%", "90%", "80%"]}
                marginTop={{ base: "8%", md: "8%", lg: "4%" }}
            >
                <Stack
                    align={"center"}
                    spacing={{ base: 8, md: 10 }}
                    py={{ base: 10, md: 28 }}
                    direction={{ base: "column", md: "row" }}
                >
                    <Stack flex={1} spacing={{ base: 10, md: 15 }}>
                        <Heading
                            lineHeight={1.1}
                            fontWeight={700}
                            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
                            textAlign={{ base: "center", md: "left" }}
                        >
                            <Text
                                as={"span"}
                                position={"relative"}
                                _after={{
                                    content: "''",
                                    width: "full",
                                    height: "30%",
                                    position: "absolute",
                                    bottom: 1,
                                    left: 0,
                                    bg: "#ef6042",
                                    zIndex: -1,
                                    transition: "opacity 0.5s",
                                }}
                            >
                                Your Partner in,
                            </Text>
                            <br />
                            <Text as={"span"} color={"#ef6042"}>
                                Web Development!
                            </Text>
                        </Heading>
                        <Text
                            color={"gray.600"}
                            textAlign={{ base: "center", md: "left", lg: "left" }}
                            fontSize={{ base: "md", lg: "lg" }}
                            overflowWrap="break-word"
                        >
                            Welcome to our world of web development excellence! At our core,
                            we're a dynamic web development company that specializes in
                            crafting remarkable websites and e-commerce platforms. With our
                            expertise in blockchain, SEO, PWAs, and more, we're here to propel
                            your business to digital success. Our team of dedicated experts is
                            passionate about delivering cutting-edge solutions tailored to your
                            unique needs, ensuring your digital journey is nothing short of
                            extraordinary.
                        </Text>
                        <Stack
                            spacing={{ base: 4, sm: 6 }}
                            direction={{ base: "column", sm: "row" }}
                            align={{ base: "center", sm: "flex-start" }}
                        >
                            <Link to="/contact">
                                <Button
                                    rounded={"full"}
                                    size={"lg"}
                                    fontWeight={"normal"}
                                    px={6}
                                    colorScheme={"red"}
                                    bg={"#ef6042"}
                                    _hover={{ bg: "#d94c32" }}
                                >
                                    Get started
                                </Button>
                            </Link>
                        </Stack>
                    </Stack>
                    <Box
                        flex={1}
                        w={"100%"}
                        borderRadius={"xl"}
                        boxShadow={"2xl"}
                        overflow={"hidden"}
                        position={"relative"}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <Image
                            src={imageUrls[currentImageIndex]}
                            alt="Carousel Image"
                            style={{
                                opacity: 1,
                                transition: "opacity 0.5s",
                            }}
                        />
                    </Box>
                </Stack>
            </Container>
        </ChakraProvider>
    );
}

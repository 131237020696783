import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Center,
  ChakraProvider,
  useToast,
  Text,
} from '@chakra-ui/react';
import Header from '../HeaderComponents/Header';
import NewsLetter from '../FooterComponents/Newsletter';
import Footer from '../FooterComponents/Footer';

const UploadDocuments = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    pan: null,
    addressProof: null,
    registrationNumber: null,
    bankAccount: null,
    identityProof: null,
    passportPhoto: null,
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    pan: '',
    addressProof: '',
    registrationNumber: '',
    bankAccount: '',
    identityProof: '',
    passportPhoto: '',
  });

  const toast = useToast();

  const handleFileChange = (e) => {
    const name = e.target.name;
    setFormData({
      ...formData,
      [name]: e.target.files[0],
    });
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};

    // Required fields
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    if (!formData.email) {
      errors.email = 'Email is required';
    }
    if (!formData.phone) {
      errors.phone = 'Phone Number is required';
    }

    // File format validation (PDF)
    const acceptedFormats = ['.pdf'];
    for (const key in formData) {
      if (formData[key]) {
        if (!acceptedFormats.some((format) => formData[key].name.endsWith(format))) {
          errors[key] = 'File format must be PDF';
        }
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const form = new FormData();
      for (const key in formData) {
        if (formData[key]) {
          form.append(key, formData[key]);
        }
      }

      try {
        await axios.post('https://db-be-hheq.onrender.com/api/upload', form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Show a success toast
        toast({
          title: 'Documents uploaded successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <ChakraProvider>
        <Header />
        <Center h="120vh">
          <Box w={{ base: '90%', md: '60%', lg: '50%' }} bg="white" p={8} borderRadius="lg" boxShadow="lg">
            <Heading as="h2" size="lg" textAlign="center" mt="8" mb="6">
              Upload Documents
            </Heading>
            <form onSubmit={handleSubmit}>
              <FormControl mb="4">
                <FormLabel htmlFor="name" mb="0">
                  Name:
                </FormLabel>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <Text color="red.500">{formErrors.name}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="email" mb="0">
                  Email:
                </FormLabel>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <Text color="red.500">{formErrors.email}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="phone" mb="0">
                  Phone Number:
                </FormLabel>
                <Input
                  id ="phone"
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <Text color="red.500">{formErrors.phone}</Text>
              </FormControl>

              {/* Add similar FormControl components for file inputs as you did before */}
              <FormControl mb="4">
                <FormLabel htmlFor="pan" mb="0">
                  PAN of the business entity:
                </FormLabel>
                <Input
                  id="pan"
                  type="file"
                  name="pan"
                  onChange={handleFileChange}
                  accept=".pdf"
                  variant="outline"
                  _hover={{ borderColor: 'red.500' }}
                  _focus={{ borderColor: 'red.500' }}
                  borderColor="gray.300"
                />
                <Text color="red.500">{formErrors.pan}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="addressProof" mb="0">
                  Address Proof (electricity bill):
                </FormLabel>
                <Input
                  id="addressProof"
                  type="file"
                  name="addressProof"
                  onChange={handleFileChange}
                  accept=".pdf"
                  variant="outline"
                  _hover={{ borderColor: 'red.500' }}
                  _focus={{ borderColor: 'red.500' }}
                  borderColor="gray.300"
                />
                <Text color="red.500">{formErrors.addressProof}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="registrationNumber" mb="0">
                  Registration/Certification Number:
                </FormLabel>
                <Input
                  id="registrationNumber"
                  type="file"
                  name="registrationNumber"
                  onChange={handleFileChange}
                  accept=".pdf"
                  variant="outline"
                  _hover={{ borderColor: 'red.500' }}
                  _focus={{ borderColor: 'red.500' }}
                  borderColor="gray.300"
                />
                <Text color="red.500">{formErrors.registrationNumber}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="bankAccount" mb="0">
                  Bank account details with Cancelled Cheque:
                </FormLabel>
                <Input
                  id="bankAccount"
                  type="file"
                  name="bankAccount"
                  onChange={handleFileChange}
                  accept=".pdf"
                  variant="outline"
                  _hover={{ borderColor: 'red.500' }}
                  _focus={{ borderColor: 'red.500' }}
                  borderColor="gray.300"
                />
                <Text color="red.500">{formErrors.bankAccount}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="identityProof" mb="0">
                  Identity Proof (Aadhaar Card, Voter Id Card, Passport):
                </FormLabel>
                <Input
                  id="identityProof"
                  type="file"
                  name="identityProof"
                  onChange={handleFileChange}
                  accept=".pdf"
                  variant="outline"
                  _hover={{ borderColor: 'red.500' }}
                  _focus={{ borderColor: 'red.500' }}
                  borderColor="gray.300"
                />
                <Text color="red.500">{formErrors.identityProof}</Text>
              </FormControl>

              <FormControl mb="4">
                <FormLabel htmlFor="passportPhoto" mb="0">
                  Passport size photograph:
                </FormLabel>
                <Input
                  id="passportPhoto"
                  type="file"
                  name="passportPhoto"
                  onChange={handleFileChange}
                  accept=".pdf"
                  variant="outline"
                  _hover={{ borderColor: 'red.500' }}
                  _focus={{ borderColor: 'red.500' }}
                  borderColor="gray.300"
                />
                <Text color="red.500">{formErrors.passportPhoto}</Text>
              </FormControl>

              <Center mt="6">
                <Button type="submit" colorScheme="red" size="lg" width="100%">
                  Upload Documents
                </Button>
              </Center>
            </form>
          </Box>
        </Center>
      </ChakraProvider>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default UploadDocuments;

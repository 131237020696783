import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  Text,
  Spinner,
  ModalFooter,
  useDisclosure,
  VStack,
  HStack,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import * as XLSX from 'xlsx';



const themeColor = 'red';

const CertificatesList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState({
    title: '',
    description: '',
    price: 0,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [certificatesPerPage] = useState(10);
  const toast = useToast();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://db-be-hheq.onrender.com/certificatesList');
      const data = await response.json();
      // console.log(data);
      setCertificates(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addCertificate = async () => {
    try {
      const response = await fetch('https://db-be-hheq.onrender.com/certificatesList', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedCertificate),
      });
      if (response.ok) {
        onClose();
        fetchData();
        toast({
          title: 'Certificate Added',
          description: 'The certificate has been added successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error adding certificate:', error);
      toast({
        title: 'Error Adding Certificate',
        description: 'An error occurred while adding the certificate.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditClick = (certificate) => {
    setSelectedCertificate(certificate);
    onOpen();
  };

  const handleUpdateClick = async () => {
    try {
      const response = await fetch(
        `https://db-be-hheq.onrender.com/certificatesList/${selectedCertificate._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedCertificate),
        }
      );
      if (response.ok) {
        onClose();
        fetchData();
        toast({
          title: 'Certificate Updated',
          description: 'The certificate has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error updating certificate:', error);
      toast({
        title: 'Error Updating Certificate',
        description: 'An error occurred while updating the certificate.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClick = async (certificateId) => {
    try {
      const response = await fetch(`https://db-be-hheq.onrender.com/certificatesList/${certificateId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchData();
        toast({
          title: 'Certificate Deleted',
          description: 'The certificate has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting certificate:', error);
      toast({
        title: 'Error Deleting Certificate',
        description: 'An error occurred while deleting the certificate.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredCertificates = certificates.filter((certificate) =>
    certificate.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const exportToExcel = () => {
    const data = filteredCertificates.map((certificate) => ({
      Title: certificate.title,
      Description: certificate.description,
      Price: `₹${certificate.price}`,
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Certificates');
    XLSX.writeFile(wb, 'certificates.xlsx');
  };


  return (
    <>
      <Box p="4" bg={"white"}>
        <HStack style={{ display: "flex", justifyContent: "space-between" }} mb={2}>
          <Heading m={2}> Certificates List</Heading>
          <Text fontWeight={"bolder"} color={"red"}> Total Certificates : {certificates.length}</Text>
          <Button colorScheme="red" onClick={exportToExcel}>
            Export to Excel
          </Button>

        </HStack>
        <HStack mb="4">
          <Input
            placeholder="Search by title"
            value={searchTerm}
            bg="white"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton
            colorScheme={themeColor}
            aria-label="Search"
            icon={<SearchIcon />}
          />
          <Button
            colorScheme={themeColor}
            onClick={() => {
              setSelectedCertificate({ title: '', description: '', price: 0 });
              onOpen();
            }}
          >
            Add Certificate
          </Button>
        </HStack>
        {isLoading ? (
          <Spinner size="xl" color={themeColor} style={{ marginTop: "20%", marginLeft: "50%" }} />
        ) : certificates.length > 0 ? (
          <Table variant="striped" colorScheme="red">
            <Thead bg="white" fontWeight="bolder">
              <Tr>
                <Th>Title</Th>
                <Th>Description</Th>
                <Th>Price</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredCertificates
                .slice(
                  (currentPage - 1) * certificatesPerPage,
                  currentPage * certificatesPerPage
                )
                .map((certificate) => (
                  <Tr key={certificate._id}>
                    <Td>{certificate.title}</Td>
                    <Td>{certificate.description}</Td>
                    <Td>{`₹${certificate.price}`}</Td>
                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          aria-label="Edit"
                          icon={<EditIcon />}
                          color={"blue"}
                          background={"transparent"}
                          _hover={{ color: "white", bg: "blue" }}
                          onClick={() => handleEditClick(certificate)}
                        />
                        <IconButton
                          colorScheme="red"
                          color={"red"}
                          background={"transparent"}
                          aria-label="Delete"
                          _hover={{ color: "white", bg: "red" }}
                          icon={<DeleteIcon />}
                          onClick={() => handleDeleteClick(certificate._id)}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        ) : (
          <p>No certificates found.</p>
        )}
        <Box mt="4">
          {certificates.length > certificatesPerPage && (
            <Button
              colorScheme='red'
              size={"sm"}
              onClick={() => setCurrentPage(currentPage - 1)}
              isDisabled={currentPage === 1}
              margin={"5px"}
            >
              Previous
            </Button>
          )}
          {certificates.length > certificatesPerPage && (
            <Button
              size={"sm"}
              colorScheme='red'
              margin={"5px"}
              onClick={() => setCurrentPage(currentPage + 1)}
              isDisabled={currentPage === Math.ceil(certificates.length / certificatesPerPage)}
            >
              Next
            </Button>
          )}
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCertificate._id ? 'Edit Certificate' : 'Add Certificate'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Input
                placeholder="Title"
                value={selectedCertificate.title}
                onChange={(e) => setSelectedCertificate({ ...selectedCertificate, title: e.target.value })}
              />
              <Input
                placeholder="Description"
                value={selectedCertificate.description}
                onChange={(e) =>
                  setSelectedCertificate({ ...selectedCertificate, description: e.target.value })
                }
              />
              <Input
                placeholder="Price"
                type="number"
                value={selectedCertificate.price}
                onChange={(e) =>
                  setSelectedCertificate({ ...selectedCertificate, price: parseFloat(e.target.value) })
                }
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={themeColor}
              onClick={selectedCertificate._id ? handleUpdateClick : addCertificate}
            >
              {selectedCertificate._id ? 'Update' : 'Add'}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CertificatesList;

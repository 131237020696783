import React from "react";
import CertificatePagebody from "../components/CertificatePageComponents/CertificateBody";
import NewContact from "../components/ContactComponents/Contact";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "../components/HeaderComponents/Header";
import NewsLetter from "../components/FooterComponents/Newsletter";
import Footer from "../components/FooterComponents/Footer";
import Backtotop from "../components/Backtotop/Backtotop";
import ContactFloating from "../components/ContactFLoating/ContactFloating";
import { Helmet } from "react-helmet";


const CertificationPage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Helmet>
        <meta name="Digiblocks | QMS Certifications" content="Digiblocks | QMS Certifications" />
        <title>Digiblocks | QMS Certifications</title>
      </Helmet>
      <ChakraProvider>
        <Header />
        <Backtotop />
        <ContactFloating />
      </ChakraProvider>
      <ChakraProvider><CertificatePagebody /></ChakraProvider>
      <NewContact />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default CertificationPage;

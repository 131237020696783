import React, { useEffect, useState } from 'react';
import {
  Box,
  ButtonGroup,
  Container,
  Text,
  IconButton,
  Heading,
  Switch,
  Button,
  Spinner,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  Grid,
  GridItem,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import * as XLSX from 'xlsx';



function CertificatesPage() {
  const [data, setData] = useState([]);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatus, setSelectedStatus] = useState('');
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    // Filter data based on the search term
    const filtered = data.filter((certification) => {
      const searchString = `${certification.contactInfo?.name} ${certification.contactInfo?.email} ${certification.contactInfo?.phone} ${certification.certificationInfo?.gstin} ${certification.certificationInfo?.panCard}`;
      return searchString.toLowerCase().includes(searchTerm);
    });
    setFilteredData(filtered);
  };

  const handleResetSearch = () => {
    setSearchTerm('');
    setFilteredData(data);
  };


  useEffect(() => {
    // Fetch and set data when the component mounts
    fetch('https://db-be-hheq.onrender.com/certifications')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setIsLoading(false); // Set loading to false when data is loaded
        setFilteredData(data); // Initialize the filtered data with the fetched data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false in case of an error
      });
  }, []);

  const handleEditClick = (certification) => {
    setSelectedCertification(certification);
    onOpen();
  };

  const handleUpdateClick = () => {
    if (selectedCertification) {
      fetch(`https://db-be-hheq.onrender.com/certifications/${selectedCertification._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedCertification),
      })
        .then((response) => response.json())
        .then(() => {
          onClose();
          fetchData();
          // Display a success toast
          toast({
            title: 'Certification Updated',
            description: 'The certification has been successfully updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Error updating certification:', error);
          // Display an error toast
          toast({
            title: 'Error',
            description: 'An error occurred while updating the certification.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const handleDeleteClick = (certificationId) => {
    if (certificationId) {
      // Display a confirmation toast
      toast({
        title: 'Delete Certification',
        description: 'Are you sure you want to delete this certification?',
        status: 'warning',
        duration: null,
        isClosable: false,
        position: 'top',
        render: ({ onClose }) => (
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              // User confirmed deletion, so proceed with deletion and close the toast
              onDeleteConfirmation(certificationId);
              onClose();
            }}
          >
            Yes, Delete
          </Button>
        ),
      });
    }
  };

  const onDeleteConfirmation = (certificationId) => {
    fetch(`https://db-be-hheq.onrender.com/certifications/${certificationId}`, {
      method: 'DELETE',
    })
      .then(() => {
        fetchData();
        toast({
          title: 'Certification Deleted',
          description: 'The certification has been successfully deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Error deleting certification:', error);
        toast({
          title: 'Error',
          description: 'An error occurred while deleting the certification.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };
  const handleUpdateStatus = (certification) => {
    if (certification && selectedStatus) {
      // Send a PATCH request to update the status in the backend
      fetch(`https://db-be-hheq.onrender.com/certifications/${certification._id}/update-status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: selectedStatus }), // Use the selectedStatus here
      })
        .then((response) => response.json())
        .then((updatedCertification) => {
          // Update the certification status in the frontend with the response from the server
          certification.status = updatedCertification.status;
          // Display a success toast
          toast({
            title: 'Certification Status Updated',
            description: 'The certification status has been successfully updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error('Error updating certification status:', error);
          // Display an error toast
          toast({
            title: 'Error',
            description: 'An error occurred while updating the certification status.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const fetchData = () => {
    fetch('https://db-be-hheq.onrender.com/certifications')
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching data:', error));
  };

  const flattenData = (certification) => {
    return {
      'Name': certification.contactInfo.name,
      'Email': certification.contactInfo.email,
      'Phone': certification.contactInfo.phone,
      'Certification': certification.certificationInfo.certification,
      'PAN Card': certification.certificationInfo.panCard,
      'GSTIN': certification.certificationInfo.gstin,
      'Comments': certification.additionalInfo.comments,
      'Payment Amount': certification.paymentInfo.amount,
      'Payment Method': certification.paymentInfo.Method,
      'Payment Date': new Date(certification.paymentInfo.time).toLocaleString(),
      'Application Status': certification.status,
    };
  };

  const exportToExcel = () => {
    if (data.length === 0) {
      // No data to export
      return;
    }

    const flattenedData = data.map(flattenData);

    const ws = XLSX.utils.json_to_sheet(flattenedData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Certificates');

    // Generate a unique file name
    const date = new Date();
    const fileName = `certificates_${date.toISOString()}.xlsx`;

    XLSX.writeFile(wb, fileName);
  };

  const handleFilterByStatus = (status) => {
    if (status === 'all') {
      setFilteredData(data); // Show all data
    } else {
      // Filter data based on the selected status
      const filtered = data.filter((certification) => certification.status === status);
      setFilteredData(filtered);
    }
  };


  const handleSortToggle = () => {
    // Toggle the sorting order between 'asc' and 'desc'
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    // Sort the data based on paymentInfo.time
    const sortedData = [...filteredData].sort((a, b) => {
      const dateA = new Date(a.paymentInfo.time);
      const dateB = new Date(b.paymentInfo.time);
      if (newSortOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setFilteredData(sortedData);
  };
  return (
    <>
      <Container maxW="7xl">
        <Heading as="h1" size="lg" style={{ display: "flex", justifyContent: "space-between" }} mt={3}>
          Certification Requests
          <Button colorScheme="red" size="sm" mt={2} bg={"#ef6042"} onClick={exportToExcel}>
            <DownloadIcon />  Export to Excel
          </Button>
        </Heading>
        <HStack mt={2} mb={2}>
          <Text>Sort by Recent</Text>
          <Switch
            colorScheme="red"
            isChecked={sortOrder === 'asc'}
            onChange={handleSortToggle}
          />

          <ButtonGroup spacing={4} mt={2}>
            <Button
              colorScheme="red"
              bg={"#ef6042"}
              size={"sm"}
              onClick={() => handleFilterByStatus('all')}
              isActive={selectedStatus === 'all'}
            >
              All
            </Button>
            <Button
              size={"sm"}
              bg={"#ef6042"}
              colorScheme="red"
              onClick={() => handleFilterByStatus('pending')}
              isActive={selectedStatus === 'pending'}
            >
              Pending
            </Button>
            <Button
              size={"sm"}
              bg={"#ef6042"}
              colorScheme="red"
              onClick={() => handleFilterByStatus('review')}
              isActive={selectedStatus === 'review'}
            >
              Review
            </Button>
            <Button
              size={"sm"}
              bg={"#ef6042"}
              colorScheme="red"
              onClick={() => handleFilterByStatus('approved')}
              isActive={selectedStatus === 'approved'}
            >
              Approved
            </Button>
          </ButtonGroup>
        </HStack>

        {/* Search input and reset button */}
        <Input
          type="text"
          placeholder="Search by Name, Email, Phone, GSTIN, PAN..."
          value={searchTerm}
          bg={"white"}
          onChange={handleSearchChange}
        />
        <Button
          colorScheme="gray"
          size="sm"
          mt={2}
          onClick={handleResetSearch}
          isDisabled={!searchTerm}
        >
          Reset
        </Button>
        {isLoading ? (
          <Spinner thickness="4px" m={"25%"} ml={"40%"} speed="0.65s" emptyColor="gray.200" color="red.500" size="xl" />
        ) : (
          <Grid
            templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr' }}
            gap={4}
          >
            {filteredData.map((certification, index) => (
              <GridItem key={index}>
                <Box
                  p={8}
                  borderWidth={1}
                  borderRadius="30px"
                  bg={"white"}
                  boxShadow="xl"
                  my={4}
                >
                  <VStack spacing={2} align="start">
                    <Text>
                      <strong>Name:</strong> {certification.contactInfo?.name}
                    </Text>
                    <Text>
                      <strong>Email:</strong> {certification.contactInfo?.email}
                    </Text>
                    <Text>
                      <strong>Phone:</strong> {certification.contactInfo?.phone}
                    </Text>
                    <Text>
                      <strong>Certification:</strong> {certification.certificationInfo?.certification}
                    </Text>
                    <Text>
                      <strong>PAN Card:</strong> {certification.certificationInfo?.panCard}
                    </Text>
                    <Text>
                      <strong>GSTIN:</strong> {certification.certificationInfo?.gstin}
                    </Text>
                    <Text>
                      <strong>Comments:</strong> {certification.additionalInfo?.comments}
                    </Text>
                    <Text>
                      <strong>Payable Amount:</strong> ₹ {certification.paymentInfo?.amount} + 18% GST = ₹ {certification.paymentInfo?.amount * 1.18}
                    </Text>
                    <Text>
                      <strong>Applied Date:</strong> {new Date(certification.paymentInfo.time).toLocaleString()}
                    </Text>
                    <Text>
                      <strong>Application Status:</strong> {certification.status}
                    </Text>
                    <HStack mt={4}>
                      <IconButton
                        colorScheme="blue"
                        size="sm"
                        aria-label="Edit"
                        icon={<EditIcon />}
                        onClick={() => handleEditClick(certification)}
                      />
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteClick(certification._id)}
                      />
                      <Select
                        placeholder="Select Status"
                        // value={selectedStatus} // Use selectedStatus here
                        onChange={(e) => setSelectedStatus(e.target.value)}
                      >
                        <option value="pending">Pending</option>
                        <option value="review">Review</option>
                        <option value="approved">Approved</option>
                      </Select>
                      <Button
                        colorScheme="green"
                        size="sm"
                        width="200px"
                        onClick={() => handleUpdateStatus(certification, selectedStatus)}
                      >
                        Update Status
                      </Button>
                    </HStack>

                  </VStack>
                </Box>
              </GridItem>
            ))}
          </Grid>
        )}
      </Container>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Certification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <Input
                type="text"
                placeholder="Name"
                value={selectedCertification?.contactInfo?.name || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    contactInfo: { ...selectedCertification.contactInfo, name: e.target.value },
                  })
                }
              />
              <Input
                type="text"
                placeholder="Email"
                value={selectedCertification?.contactInfo?.email || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    contactInfo: { ...selectedCertification.contactInfo, email: e.target.value },
                  })
                }
              />
              <Input
                type="text"
                placeholder="Phone"
                value={selectedCertification?.contactInfo?.phone || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    contactInfo: { ...selectedCertification.contactInfo, phone: e.target.value },
                  })
                }
              />
              <Input
                type="text"
                placeholder="Certification"
                value={selectedCertification?.certificationInfo?.certification || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    certificationInfo: {
                      ...selectedCertification.certificationInfo,
                      certification: e.target.value,
                    },
                  })
                }
              />
              <Input
                type="text"
                placeholder="PAN Card"
                value={selectedCertification?.certificationInfo?.panCard || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    certificationInfo: {
                      ...selectedCertification.certificationInfo,
                      panCard: e.target.value,
                    },
                  })
                }
              />
              <Input
                type="text"
                placeholder="GSTIN"
                value={selectedCertification?.certificationInfo?.gstin || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    certificationInfo: {
                      ...selectedCertification.certificationInfo,
                      gstin: e.target.value,
                    },
                  })
                }
              />
              <Input
                type="text"
                placeholder="Comments"
                value={selectedCertification?.additionalInfo?.comments || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    additionalInfo: { ...selectedCertification.additionalInfo, comments: e.target.value },
                  })
                }
              />
              <Input
                type="text"
                placeholder="Payment Amount"
                value={selectedCertification?.paymentInfo?.amount || ''}
                onChange={(e) =>
                  setSelectedCertification({
                    ...selectedCertification,
                    paymentInfo: { ...selectedCertification.paymentInfo, amount: e.target.value },
                  })
                }
              />

            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleUpdateClick}>
              Update
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CertificatesPage;

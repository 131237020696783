import { StarIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";

const StarRatingDisplay = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;

  const renderStars = () => {
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} color="yellow.500" />);
    }

    if (hasHalfStar) {
      stars.push(<StarIcon key="half" color="yellow.500" />);
    }

    const remainingStars = 5 - (fullStars + (hasHalfStar ? 1 : 0));

    for (let i = 0; i < remainingStars; i++) {
      stars.push(<StarIcon key={`empty-${i}`} color="gray.300" />);
    }

    return stars;
  };

  return <Flex mt={"20px"}>{renderStars()}</Flex>;
};

export default StarRatingDisplay;
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Text,
  Flex,
  Icon,
  IconButton,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { DownloadIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import axios from 'axios';

const RegistrationPage = () => {
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteDocumentId, setDeleteDocumentId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchDocuments = async () => {
    try {
      const response = await axios.get('https://db-be-hheq.onrender.com/api/documents');
      setDocuments(response.data);
      setFilteredDocuments(response.data); // Initialize filteredDocuments with all documents
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const downloadDocument = (document, fieldName) => {
    const downloadUrl = `https://db-be-hheq.onrender.com/api/documents/${document[fieldName]}`;
    window.location.href = downloadUrl;
  };

  const handleDeleteClick = (documentId) => {
    setDeleteDocumentId(documentId);
    onOpen();
  };

  const handleConfirmDelete = async () => {
    try {
      // Send a DELETE request to delete the document by ID
      await axios.delete(`https://db-be-hheq.onrender.com/api/documents/${deleteDocumentId}`);
      onClose();
      // Refresh the documents list after deletion
      fetchDocuments();
    } catch (error) {
      console.error(error);
    }
  };

  // Handle search input change and filter documents
  const handleSearch = () => {
    const filtered = documents.filter((document) => {
      const searchFields = [document.name, document.email, document.phone];
      return searchFields.some((field) => field.toLowerCase().includes(searchTerm.toLowerCase()));
    });
    setFilteredDocuments(filtered);
  };

  return (
    <div>
      <Flex mb={4} alignItems="center" m={4}>
        <Input
          placeholder="Search by name, email, or phone"
          value={searchTerm}
          bg={"white"}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button ml={4} bg="#ef6042" color={"white"} leftIcon={<SearchIcon />} onClick={handleSearch}>
          Search
        </Button>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6} m={4}>
        {filteredDocuments.map((document) => (
          <GridItem key={document._id} bg={"white"}>
            <Box borderWidth="1px" p={6} rounded="xl">
              <Text fontSize="lg" fontWeight="bold" mt="2">
                Name: {document.name}
              </Text>
              <Text fontSize="lg">Email: {document.email}</Text>
              <Text fontSize="lg">Phone: {document.phone}</Text>
              <Flex justifyContent={'space-between'} mb={2}>
                <Text fontSize="xl" mr="2">
                  PAN
                </Text>
                <IconButton
                  icon={<DownloadIcon />}
                  colorScheme="blue"
                  aria-label="Download"
                  size="sm"
                  onClick={() => downloadDocument(document, 'pan')}
                />
              </Flex>
              <Flex justifyContent={'space-between'} mb={2}>
                <Text fontSize="xl" mr="2">
                  Address Proof
                </Text>
                <IconButton
                  icon={<DownloadIcon />}
                  colorScheme="blue"
                  aria-label="Download"
                  size="sm"
                  onClick={() => downloadDocument(document, 'addressProof')}
                />
              </Flex>
              <Flex justifyContent={'space-between'} mb={2}>
                <Text fontSize="xl" mr="2">
                  Registration Number
                </Text>
                <IconButton
                  icon={<DownloadIcon />}
                  colorScheme="blue"
                  aria-label="Download"
                  size="sm"
                  onClick={() => downloadDocument(document, 'registrationNumber')}
                />
              </Flex>
              <Flex justifyContent={'space-between'} mb={2}>
                <Text fontSize="xl" mr="2">
                  Bank Account
                </Text>
                <IconButton
                  icon={<DownloadIcon />}
                  colorScheme="blue"
                  aria-label="Download"
                  size="sm"
                  onClick={() => downloadDocument(document, 'bankAccount')}
                />
              </Flex>
              <Flex justifyContent={'space-between'} mb={2}>
                <Text fontSize="xl" mr="2">
                  Identity Proof
                </Text>
                <IconButton
                  icon={<DownloadIcon />}
                  colorScheme="blue"
                  aria-label="Download"
                  size="sm"
                  onClick={() => downloadDocument(document, 'identityProof')}
                />
              </Flex>
              <Flex justifyContent={'space-between'} mb={2}>
                <Text fontSize="xl" mr="2">
                  Passport Photo
                </Text>
                <IconButton
                  icon={<DownloadIcon />}
                  colorScheme="blue"
                  aria-label="Download"
                  size="sm"
                  onClick={() => downloadDocument(document, 'passportPhoto')}
                />
              </Flex>
              <IconButton
              ml={"45%"}
              mt={"2%"}
                icon={<DeleteIcon />}
                colorScheme="red"
                aria-label="Delete"
                size="sm"
                onClick={() => handleDeleteClick(document._id)}
              />
            </Box>
          </GridItem>
        ))}
      </Grid>

      {/* Delete Confirmation Dialog */}
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={null}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Document
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure you want to delete this document?</AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default RegistrationPage;

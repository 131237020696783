import React, { useState } from "react";

const FAQ = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  return (
    <div className=" 2xl:container 2xl:mx-auto md:py-12 lg:px-20 md:px-6 py-9 px-4 lg:mt-10">
      <p className="mt-2 text-xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        Frequently Asked Questions
      </p>
      <div className="mt-4 flex md:justify-between md:items-start md:flex-row flex-col justify-start items-start">
        <div className=" ">
          <h2 className="font-normal text-lg leading-6 text-black lg:w-8/12 md:w-9/12 lg:whitespace-nowrap">
            Here are a few of the most frequently asked questions by our
            valuable customers
          </h2>
        </div>
      </div>
      <div className=" flex md:flex-row flex-col md:space-x-8 md:mt-16 mt-8">
        <div className=" md:w-5/12 lg:w-4/12 w-full ">
          <img
            src="https://img.freepik.com/free-vector/flat-young-businessman-sitting-huge-red-question-mark_126523-2881.jpg?w=1380&t=st=1695998006~exp=1695998606~hmac=569e8296bb43c983d9e442460047b1400eb35ccbb861c151fde8bb7c9a0d2ac5"
            alt="Img of Glass bottle"
            className="w-full md:block hidden"
          />
        </div>
        <div className=" md:w-7/12 lg:w-8/12 w-full lg:mt-12 md:mt-0 sm:mt-14 mt-10">
          {/* <!-- Shipping Section --> */}
          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className=" font-semibold text-xl leading-5 text-gray-800">
                What services related to quality does Digiblocks offer?
              </h3>
              <button
                aria-label="too"
                className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                onClick={() => setShow(!show)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={show ? "hidden" : "block"}
                    d="M10 4.1665V15.8332"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16602 10H15.8327"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <p
              className={
                "font-normal text-base leading-6 text-gray-600 mt-4 w-11/12 " +
                (show ? "block" : "hidden")
              }
            >
              Digiblocks offers a wide range of quality certifications to ensure
              your products meet the highest industry standards. We are
              committed to delivering high-quality services that not only meet
              but exceed our customers' expectations.
            </p>
          </div>

          <hr className=" my-7 bg-gray-200" />

          {/* <!-- Returns Section --> */}

          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className=" font-semibold text-xl leading-5 text-gray-800">
                Are Digiblocks' pricing competitive in the market?
              </h3>
              <button
                aria-label="too"
                className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                onClick={() => setShow2(!show2)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={show2 ? "hidden" : "block"}
                    d="M10 4.1665V15.8332"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16602 10H15.8327"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <p
              className={
                "font-normal text-base leading-6 text-gray-600 mt-4 w-11/12 " +
                (show2 ? "block" : "hidden")
              }
            >
              Yes, Digiblocks offers competitive pricing to ensure you receive
              excellent value for your investment. We are dedicated to providing
              high-quality services at prices that align with industry
              standards.
            </p>
          </div>

          <hr className=" my-7 bg-gray-200" />

          {/* <!-- Exchange Section --> */}

          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className=" font-semibold text-xl leading-5 text-gray-800">
                What industries does Digiblocks serve with its quality
                certification services?
              </h3>
              <button
                aria-label="too"
                className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                onClick={() => setShow3(!show3)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={show3 ? "hidden" : "block"}
                    d="M10 4.1665V15.8332"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16602 10H15.8327"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <p
              className={
                "font-normal text-base leading-6 text-gray-600 mt-4 w-11/12 " +
                (show3 ? "block" : "hidden")
              }
            >
              Digiblocks serves a wide range of industries with its quality
              certification services, including manufacturing, healthcare,
              technology, and more. We tailor our services to meet the unique
              needs of each industry.
            </p>
          </div>

          <hr className=" my-7 bg-gray-200" />

          {/* Tracking Section */}

          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className=" font-semibold text-xl leading-5 text-gray-800">
                What does Digiblocks provide in terms of registration services?
              </h3>
              <button
                aria-label="too"
                className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                onClick={() => setShow4(!show4)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={show4 ? "hidden" : "block"}
                    d="M10 4.1665V15.8332"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16602 10H15.8327"
                    stroke="#1F2937"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <p
              className={
                "font-normal text-base leading-6 text-gray-600 mt-4 w-11/12 " +
                (show4 ? "blcok" : "hidden")
              }
            >
              Digiblocks offers seamless registration services that simplify the
              process, allowing you to focus on growing your business. Our team
              of experts is ready to provide valuable advice to assist you in
              making well-informed decisions during the registration process.
            </p>
          </div>

          <hr className=" my-7 bg-gray-200" />
        </div>
      </div>
    </div>
  );
};

export default FAQ;

import React from 'react';
import { Button } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import { DownloadIcon } from '@chakra-ui/icons';

const ExportToExcelButton = ({ data }) => {
    const exportToExcel = () => {
        try {
            // Create a new workbook and add a worksheet
            const workbook = XLSX.utils.book_new();
            const wsName = 'Contacts'; // Worksheet name
            const wsData = [
                // Header row
                ['Name', 'Email', 'Phone', 'Message', 'Status', 'Timestamp'],
                // Data rows
                ...data.map(contact => [
                    contact.name,
                    contact.email,
                    contact.phone,
                    contact.message,
                    contact.status ? 'Resolved' : 'Pending',
                    contact.timestamp
                ])
            ];

            // Create a worksheet with the data
            const ws = XLSX.utils.aoa_to_sheet(wsData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, ws, wsName);

            // Save the workbook as a file
            XLSX.writeFile(workbook, 'contacts.xlsx');
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };

    return (
        <Button onClick={exportToExcel} bg={"#ef6042"} color={"white"} mt={8} mb={4} _hover={{ bg: "red" }}>
            <DownloadIcon /> Export to Excel
        </Button>
    );
};

export default ExportToExcelButton;

import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Header from '../components/HeaderComponents/Header';
import NewsLetter from '../components/FooterComponents/Newsletter';
import Footer from '../components/FooterComponents/Footer';
import { PinInput, PinInputField } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import bg from '../assets/blue-orange-cupboards_23-2148518393.jpg';

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const toast = useToast();
  let navigate = useNavigate();

  const sendOTP = async () => {
    try {
      // Send an email with OTP to the provided email
      const response = await axios.post('https://db-be-hheq.onrender.com/api/generate-otp', { email });

      if (response.data.success) {
        setIsOtpSent(true);
        toast({
          title: 'OTP sent successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Failed to send OTP. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error sending OTP. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  let dologin = async () => {
    let val = await axios.post("https://db-be-hheq.onrender.com/adminlogin/do-login");
    navigate("/admin")
  }

  const handleLogin = async () => {
    try {
      // Send the OTP to your server for validation
      const response = await axios.post('https://db-be-hheq.onrender.com/api/verify-otp', { email, otp });

      if (response.data.success) {

        dologin();
        toast({
          title: 'Login successful.',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        // Redirect or perform the necessary actions here
      } else {
        toast({
          title: 'Invalid OTP. Please try again.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error validating OTP. Please try again.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="Digiblocks | Admin Login" content="Digiblocks | Admin Login" />
        <title>Digiblocks | Admin Login</title>
      </Helmet>
      <Header />
      <Box
        bgImage={`url(${bg})`}
        bgSize="cover"
        bgPosition="center"
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          p={4}
          borderRadius="lg"
          w={{ base: '90%', sm: '80%', md: '60%', lg: '50%' }}
          p="4%"
          textAlign="center"
          border="1px solid white"
        >
          <Text color="white">Hey Admin !!! Nice to See You Again</Text>
          <Heading as="h2" size="xl" color="white" mb="4">
            Welcome Back
          </Heading>
          <FormControl>
            <FormLabel color="white" fontSize="xl">
              Email
            </FormLabel>
            <Input
              type="email"
              value={email}
              color="white"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="admin@example.com"
              size="md"
            />
          </FormControl>
          {!isOtpSent ? (
            <Button
              colorScheme="red"
              size="md"
              mt="4"
              onClick={sendOTP}
              _hover={{ bg: 'red.600' }}
            >
              Send OTP
            </Button>
          ) : (
            <>
              <FormControl mt="10px">
                <FormLabel color="white" fontSize="xl">
                  OTP
                </FormLabel>
                <PinInput value={otp} onChange={(value) => setOtp(value)} color="white">
                  <PinInputField ml="15px" />
                  <PinInputField ml="15px" />
                  <PinInputField ml="15px" />
                  <PinInputField ml="15px" />
                </PinInput>
              </FormControl>
              <Button
                colorScheme="red"
                size="md"
                mt="6"
                onClick={handleLogin}
                _hover={{ bg: 'red.600' }}
              >
                Login
              </Button>
            </>
          )}
        </Box>
      </Box>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default AdminLogin;

import {
    Box, Button, Flex, Heading, Input, SimpleGrid, Text, InputGroup, InputRightElement, Skeleton, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { MyContext } from "../../Context/Appcontext";
import axios from "axios";

const CertificatePagebody = () => {
    let [array, setArray] = useState([]);
    let { myData, setMyData } = useContext(MyContext);
    let [show1, setshow1] = useState(true);
    let [show2, setshow2] = useState(true);
    let [show3, setshow3] = useState(true);
    let [show4, setshow4] = useState(true);
    let [show5, setshow5] = useState(true);
    let [show6, setshow6] = useState(true);
    let [show7, setshow7] = useState(true);
    let [show8, setshow8] = useState(true);
    let [show9, setshow9] = useState(true);
    let [show10, setshow10] = useState(true);
    let [show11, setshow11] = useState(true);


    let getdata = async () => {
        let val = await axios.get("https://db-be-hheq.onrender.com/certificatesList");
        setArray(val.data)
        console.log(val.data)
    }

    const categorizedCertificates = {
        AntiBribery: [],//done
        Aerospace: [],//done
        SafetyAndQuality: [],//done
        TestingAndCompliance: [],  //done
        MedicalDevices: [],//done
        InformationSecurity: [],//done
        EnergyManagement: [],//done
        FoodSafety: [],//done
        EthicalAndSustainableSourcing: [],//done
        RegulatoryCompliance: [],
        GeneralCertifications: [],
    };

    array.forEach((certificate) => {
        const { title, description } = certificate;
        if (title.includes('ISO 37001') || description.includes('Anti-Bribery Management System')) {
            categorizedCertificates.AntiBribery.push(certificate);
        } else if (description.includes('aerospace')) {
            categorizedCertificates.Aerospace.push(certificate);
        } else if (title.includes('ISO 9001') || title.includes('ISO 14001') || title.includes('ISO 45001')) {
            categorizedCertificates.SafetyAndQuality.push(certificate);
        } else if (
            title.includes('ISO/IEC 17025') ||
            title.includes('ISO 10377:2018') ||
            title.includes('ISO 15000-5:2014')
        ) {
            categorizedCertificates.TestingAndCompliance.push(certificate);
        } else if (description.includes('medical device')) {
            categorizedCertificates.MedicalDevices.push(certificate);
        } else if (title.includes('ISO 27701:2019') || title.includes('ISO/IEC 27010-2019')) {
            categorizedCertificates.InformationSecurity.push(certificate);
        } else if (title.includes('ISO 50001')) {
            categorizedCertificates.EnergyManagement.push(certificate);
        } else if (title.includes('ISO 22000')) {
            categorizedCertificates.FoodSafety.push(certificate);
        } else if (description.includes('ethical') || description.includes('sustainable sourcing')) {
            categorizedCertificates.EthicalAndSustainableSourcing.push(certificate);
        } else if (title.includes('Compliance')) {
            categorizedCertificates.RegulatoryCompliance.push(certificate);
        } else {
            categorizedCertificates.GeneralCertifications.push(certificate);
        }
    });
    let btnref = useRef(null);
    let [filter, setfilter] = useState("");
    let [data, setdata] = useState(array);
    let [temp, settemp] = useState(array);
    let [expandall, setExpandall] = useState(true);

    useEffect(() => {
        getdata();
        filterFunction();

    }, [filter, expandall]);

    const filterFunction = () => {
        if (filter !== "") {
            let x = array.filter((e) => e && e.title && e.title.toLowerCase().includes(filter.toLowerCase()));
            if (x.length > 0)
                settemp(x);
            else {
                settemp([])
            }

        }
    };

    let setdataincontext = (e) => {
        setMyData(e);
    }
    return (
        <Box  textAlign={"center"} marginTop={["15%","12%","6%"]} marginBottom={"5%"}>
            <Flex
                direction={"column"}
                w={"100%"}
                m={"auto"}
                mt={0}
                
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Flex
                    flex={2}
                    justifyContent={"center"}
                    p={"20px"}
                    w={"100%"}
                    gap={"20px"}
                    direction={"column"}
                    
                >
                    <InputGroup m={"auto"} w={["80%", "60%", "50%"]} size="md">
                        <Input
                            onChange={(e) => {
                                setfilter(e.target.value);
                            }}
                            pr="4.5rem"
                            placeholder="Search"
                            ref={btnref}
                        />
                        <InputRightElement width="4.5rem">
                            <Button
                                h="1.75rem"
                                size="sm"
                                bg={"#ef6042"}
                                color={"white"}
                                _hover={{ bg: "#d94c32" }}
                                onClick={() => {
                                    setfilter("");
                                    btnref.current.value = "";
                                }}
                            >
                                Reset
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Button w={["35%","35%%","10%"]} m={"auto"} display={filter ? "none" : "block"} bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                        setExpandall(!expandall)
                        if (expandall === false) {
                            setshow1(true);
                            setshow2(true);
                            setshow3(true);
                            setshow4(true);
                            setshow5(true);
                            setshow6(true);
                            setshow7(true);
                            setshow8(true);
                            setshow9(true);
                            setshow10(true);
                            setshow11(true);
                        } else {
                            setshow1(false);
                            setshow2(false);
                            setshow3(false);
                            setshow4(false);
                            setshow5(false);
                            setshow6(false);
                            setshow7(false);
                            setshow8(false);
                            setshow9(false);
                            setshow10(false);
                            setshow11(false);
                        }
                    }}>{expandall ? "Hide all" : "Expand all"}</Button>
                </Flex>
                <Text color={"red"}>{btnref.current && btnref.current.value != "" && temp.length == 0 ? "*No Matching results" : ""}</Text>
            </Flex>

            <Box ml={"-10%"}>
                {
                    filter !== "" && temp.length > 0 ? <>
                        <SimpleGrid columns={1} gap="20px" p={4}>
                            {temp.length > 0 ? temp.map((e, index) => (
                                <Accordion
                                    allowMultiple={false}
                                    key={index}
                                    allowToggle
                                    w={["80%", "80%", "50%"]}
                                    m="auto"
                                >
                                    <AccordionItem>
                                        <Heading>
                                            <AccordionButton
                                                _expanded={{ bg: "#ef6042", color: "white" }}
                                            >
                                                <Box as="span" flex="1" textAlign="left">
                                                    {e.title}
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </Heading>
                                        <AccordionPanel pb={4} textAlign={"left"}>
                                            {e.description}
                                            <Flex direction="column" alignItems="center" w="100%">
                                                <Flex
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    w="100%"
                                                    mt={4}
                                                >
                                                    <Link to="/single-certificate">
                                                        <Button
                                                            bg="#ef6042"
                                                            _hover={{ bg: "#d94c32" }}
                                                            color="white"
                                                            onClick={() => {
                                                                setdataincontext(e)
                                                            }}
                                                        >
                                                            Apply
                                                        </Button>
                                                    </Link>
                                                    <Flex alignItems="center">
                                                        <Text fontSize="xl" color={"green.400"}>
                                                            ₹ {e.price} + 18% GST
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                {/* Add other details as needed */}
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            )) : <Skeleton>
                                <Box w={"80%"} m={"auto"} h={"200px"}></Box>
                            </Skeleton>}
                        </SimpleGrid>
                    </> : <>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Testing And Compliance</Heading>
                                </Box>
                                <Button   bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow1(!show1);
                                }}>{show1 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show1 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.TestingAndCompliance.length > 0 ? categorizedCertificates.TestingAndCompliance.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"

                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"
                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >

                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Food Safety</Heading>

                                </Box>  <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow2(!show2);
                                }}>{show2 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show2 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.FoodSafety.length > 0 ? categorizedCertificates.FoodSafety.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"
                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Energy Management</Heading>
                                </Box>
                                <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow3(!show3);
                                }}>{show3 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid columns={1} display={show3 ? "grid" : "none"} gap="20px" p={4}>
                                {categorizedCertificates.EnergyManagement.length > 0 ? categorizedCertificates.EnergyManagement.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Anti Bribery</Heading>
                                </Box>
                                <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow4(!show4);
                                }}>{show4 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid columns={1} display={show4 ? "grid" : "none"} gap="20px" p={4}>
                                {categorizedCertificates.AntiBribery.length > 0 ? categorizedCertificates.AntiBribery.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Safety And Quality</Heading>
                                </Box>
                                <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow5(!show5);
                                }}>{show5 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show5 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.SafetyAndQuality.length > 0 ? categorizedCertificates.SafetyAndQuality.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>

                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Medical Devices</Heading>
                                </Box>
                                <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow6(!show6);
                                }}>{show6 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show6 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.MedicalDevices.length > 0 ? categorizedCertificates.MedicalDevices.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                color="white"
                                                                _hover={{ bg: "#d94c32" }}

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Aerospace</Heading>
                                </Box>
                                <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow7(!show7);
                                }}>{show7 ? "-" : "+"}</Button>
                            </Flex>

                            <SimpleGrid display={show7 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.Aerospace.length > 0 ? categorizedCertificates.Aerospace.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                color="white"
                                                                _hover={{ bg: "#d94c32" }}

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>
                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Information Security</Heading>

                                </Box> <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow8(!show8);
                                }}>{show8 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show8 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.InformationSecurity.length > 0 ? categorizedCertificates.InformationSecurity.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                color="white"
                                                                _hover={{ bg: "#d94c32" }}

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>

                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Ethical And Sustainable Sourcing</Heading>
                                </Box> <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow9(!show9);
                                }}>{show9 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show9 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.EthicalAndSustainableSourcing.length > 0 ? categorizedCertificates.EthicalAndSustainableSourcing.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>

                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• Regulatory Compliance</Heading>
                                    {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                </Box>  <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow10(!show10);
                                }}>{show10 ? "-" : "+"}</Button>
                            </Flex>
                            <SimpleGrid display={show10 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.RegulatoryCompliance.length > 0 ? categorizedCertificates.RegulatoryCompliance.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>

                        <Box>

                            <Flex w={"70%"} alignItems={"center"} m={"auto"} justifyContent={"center"}>
                                <Box m={"auto"} w={"50rem"} textAlign={"left"} >
                                    {/* <Heading size={["md", "xl", "xl"]} p={"20px"} mt={"20px"}>Energy Management</Heading> */}
                                    <Heading fontSize={["18px", "23px", "25px"]} p={"20px"} mt={"20px"}>• General Certifications</Heading>
                                </Box> <Button bg={"#ef6042"} _hover={{ bg: "#d94c32" }} color={"white"} onClick={() => {
                                    setshow11(!show11);
                                }}>{show11 ? "-" : "+"}</Button>
                            </Flex>

                            <SimpleGrid display={show11 ? "grid" : "none"} columns={1} gap="20px" p={4}>
                                {categorizedCertificates.GeneralCertifications.length > 0 ? categorizedCertificates.GeneralCertifications.map((e, index) => (
                                    <Accordion
                                        allowMultiple={false}
                                        key={index}
                                        allowToggle
                                        w={["80%", "80%", "50%"]}
                                        m="auto"
                                    >
                                        <AccordionItem>
                                            <Heading>
                                                <AccordionButton
                                                    _expanded={{ bg: "#ef6042", color: "white" }}
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {e.title}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel pb={4} textAlign={"left"}>
                                                {e.description}
                                                <Flex direction="column" alignItems="center" w="100%">
                                                    <Flex
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        w="100%"
                                                        mt={4}
                                                    >
                                                        <Link to="/single-certificate">
                                                            <Button
                                                                bg="#ef6042"
                                                                _hover={{ bg: "#d94c32" }}
                                                                color="white"

                                                                onClick={() => {
                                                                    setdataincontext(e)
                                                                }}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </Link>
                                                        <Flex alignItems="center">
                                                            <Text fontSize="xl" color={"green.400"}>
                                                                ₹ {e.price} + 18% GST
                                                            </Text>
                                                        </Flex>
                                                    </Flex>
                                                    {/* Add other details as needed */}
                                                </Flex>
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                )) : <Skeleton w={"50%"} m={"auto"}>
                                    <Box w={"50%"} m={"auto"} h={"200px"}></Box>
                                </Skeleton>}
                            </SimpleGrid>
                        </Box>
                        <Box><Text fontWeight={"bolder"} fontSize={["22px", "27px", "30px"]}>And Many More...</Text></Box>
                    </>
                }
            </Box>

        </Box>
    );
};

export default CertificatePagebody;
